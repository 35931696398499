import { FeatureCollection } from 'geojson';
import { LatLng } from 'leaflet';
import { IFilterMapQuery } from '../domain/types';
export class Maybe<TResult> {
    private constructor(success: boolean, value?: TResult, message: string = "") {
        this.success = success;
        this.value = value;
        this.message = message;
    }

    readonly success: boolean;
    readonly message: string;
    readonly value?: TResult;

    getValue(): TResult {
        return this.value as TResult;
    }

    static Success<TResult>(value: TResult): Maybe<TResult> {
        return new Maybe(true, value);
    }

    static Failed<TResult>(reason: string): Maybe<TResult> {
        return new Maybe<TResult>(false, undefined, reason);
    }
}

export declare type ViewFilter = {
    zoom:number;
    sw:LatLng;
    ne:LatLng;
    city?:string;
    polygons?:FeatureCollection;
};

export const getResultFrom = async <TResult>(response: Response, mapping?:(value:any) => TResult) => {
    const resultValue = response.status !== 204? await response.json() : true;
    const isSuccessfulStatusCode = (
        response.ok && Math.floor(response.status / 100) === 2
    );

    if(mapping) {
        return mapping(resultValue);
    }
    
    if (isSuccessfulStatusCode) {
        return (resultValue as TResult);
    }

    throw new Error(`Status Code: '${response.status}'. Message: ${resultValue.message}`);
};

export const getQueryForPolygons = (polygons?: FeatureCollection) => {
    return polygons ? `polygons=${mapJSONtoQueryParam(polygons)}` : '';
};

export const getCommonQueryString = (
    filters: IFilterMapQuery,
    city?: string,
    polygons?: FeatureCollection
) => {
    const isCut = filters.isCut && filters.isCut.toString();
        const params = [
            { key: 'genus', value: mapToQueryParam('genus', filters.genus), isCollection: true },
            { key: 'species', value: mapToQueryParam('species', filters.species), isCollection: true },
            { key: 'selection', value: mapToQueryParam('selection', filters.selection), isCollection: true },
            { key: 'district', value: mapToQueryParam('district', filters.district), isCollection: true },
            { key: 'health_state', value: mapToQueryParam('health_state', filters.health_state), isCollection: true },
            { key: 'needed_actions', value: mapToQueryParam('needed_actions', filters.needed_actions), isCollection: true },
            { key: 'max_age', value: filters.max_age },
            { key: 'min_age', value: filters.min_age },
            { key: 'is_cut', value: isCut },
            { key: 'city', value: city }
        ].filter(_ => Boolean(_.value));

        let result = params
            .map(_ => _.isCollection ? _.value : `${_.key}=${_.value}`)
            .join('&');

        if (polygons) {
            result = result
                ? `${result}&${getQueryForPolygons(polygons)}`
                : getQueryForPolygons(polygons);
        }

        return result;
};

export const getTreesCountQueryString = (
    filter:IFilterMapQuery,
    city?:string,
    polygons?:FeatureCollection
) => {
    const queryString = getCommonQueryString(filter, city, polygons);
    const streamParam = queryString ? '&stream_results=false' : 'stream_results=false';

    return `?${queryString}${streamParam}`;
};

export const getTreesQueryString = (
    userFilters:IFilterMapQuery,
    viewFilters:ViewFilter,
    page?: number
) => {
    const { city, sw, ne, polygons } = viewFilters;
    const queryString = getCommonQueryString(userFilters, city, polygons);

    const pageParam = `&page=${(page || 0)}`;
    const boundsParams = `&SW=${mapPointToWKT(sw)}&NE=${mapPointToWKT(ne)}`;
    const streamParam = queryString ? '&stream_results=false' : 'stream_results=false';

    return `?${queryString}${streamParam}&clustered=false${boundsParams}${pageParam}`;
};

export const getClustersQueryString = (
    userFilters:IFilterMapQuery,
    viewFilters:ViewFilter,
) => {
    const { city, zoom, sw, ne, polygons } = viewFilters;
    const queryString = getCommonQueryString(userFilters, city, polygons);

    const boundsParams = `&SW=${mapPointToWKT(sw)}&NE=${mapPointToWKT(ne)}`;
    const streamParam = queryString ? '&stream_results=false' : 'stream_results=false';

    return `?${queryString}${streamParam}&clustered=true&zoom_level=${zoom}${boundsParams}`;
};

export const getDownloadQueryString = (
    userFilters:IFilterMapQuery,
    city?:string,
    polygons?:FeatureCollection
) => {
    const queryString = getCommonQueryString(userFilters, city, polygons);
    const streamResult = queryString ? '&stream_results=true' : 'stream_results=true';

    return `?${queryString}${streamResult}&clustered=false&output_format=csv`;
};

const mapToQueryParam = (key:string, source?: Array<any>) => {
    return source && source.length
        ? source.map(_ => `${key}=${encodeURIComponent(_)}`).join('&')
        : '';
}

const mapJSONtoQueryParam = (source?: any) => {
    return source ? encodeURIComponent(JSON.stringify(source)) : '';
}

const mapPointToWKT = (point?: LatLng) => {
    return point ? `POINT (${point.lng} ${point.lat})` : null;
}