import { Reducer, AnyAction } from "redux";
import { UserAuthority } from "../app-state";
import { USER_LOGGED_IN, USER_LOGGED_OUT, CITY_CHANGED } from "../constants";

const initialState = new UserAuthority();
const authorityDataReducer: Reducer<UserAuthority, AnyAction> = (state:UserAuthority = initialState, action:AnyAction) => {
    let result = { ...state };

    switch(action.type) {
        case USER_LOGGED_IN: {
            const { data } = action.payload;
            delete data.refresh_token;
            delete data.access_token;

            result = { ...data };
        }; break;
        
        case USER_LOGGED_OUT: result = new UserAuthority(); break;
        case CITY_CHANGED: result.city = action.payload.city; break;
        
        default: result = state; break;
    }

    return result;
}

export default authorityDataReducer;
