export function toNumber(value: any) {
    const normalized = String(value).replace(",", ".").trim();
    const input = Number(normalized);

    return !isNaN(input) && input > 0 ? input : undefined;
}

export function toNumberString(value: any) {
    const normalized = String(value).replace(",", ".").trim();
    const input = Number(normalized);

    return !isNaN(input) && input > 0 ? normalized : undefined;
}

export function toDOBstring(value: string | number) {
    let result = undefined;
    const input = Number(value);

    if(!isNaN(input)) {
        let yearOfBirth = (new Date()).getFullYear() - input;
        result = `${yearOfBirth}-01-01`;
    }

    return result;
};
