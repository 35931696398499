import React from "react";
import { Carousel } from "react-bootstrap";
import { LoginModal } from "../common/modal-login/login-modal-component";

import './home-page.css';
import iosPreview from '../../assets/images/ios-preview.webp';
import desktopPreview from '../../assets/images/map-bg.webp';
import rivneLogoSrc from '../../assets/images/logos/rivne-rada.webp';
import lutskLogoSrc from '../../assets/images/logos/lutsk-rada.webp';
import lvivLogoSrc from '../../assets/images/logos/lviv-rada.webp';
import dubnoLogoSrc from '../../assets/images/logos/dubno-rada.webp';
import chortkivLogoSrc from '../../assets/images/logos/chortkiv-rada.webp';

import appConfig from "../../app-config";
import { Footer } from "../common/footer-component";
import { NavigationBar } from '../common/navigation-bar-component';

declare type Props = {}
class State {
    scrolled: number = 0;
}

export class HomePage extends React.PureComponent<Props, State> {
    constructor(props:Props){
        super(props);
        this.state = new State();
    }

    componentDidMount() {
        window.addEventListener('scroll', this.listenToScroll)
    }
      
    componentWillUnmount() {
        window.removeEventListener('scroll', this.listenToScroll)
    }
    
    private listenToScroll = () => {
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop
        const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        const scrolled = winScroll / height;

        this.setState({ scrolled: scrolled });
      }

    render() {
        const { scrolled: opacityOnScroll } = this.state;

        return (
            <React.Fragment>
                <NavigationBar />
                <main role="main" id="top">
                    <Carousel controls={false}>
                        <Carousel.Item>
                            <div className="bg-fixed content-pict slide-bg inspect-pict">
                                <div className="slide-gradient-cover"></div>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className="bg-fixed content-pict slide-bg care-pict">
                                <div className="slide-gradient-cover"></div>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className="bg-fixed content-pict slide-bg analysis-pict">
                                <div className="slide-gradient-cover"></div>
                            </div>
                        </Carousel.Item>
                    </Carousel>
        
                    <div className="p-5 m-auto rounded home-info-board">
                        <h2 className="text-uppercase text-light text-center header-style">
                            Система управління зеленими насадженнями
                        </h2>
                        <hr className="border-warning" />
                        <p className="text-light text-justify m-2 main-text-style">
                            Ми допоможемо вам швидко проінвентаризувати міські дерева, якісно доглядати за деревами і економити кошти за допомогою сучасної електронної сиситеми
                        </p>
                        <hr className="border-warning" />
                        <div className="text-center">
                            <a href="#about-us" className="btn btn-outline-warning">Дізнатись більше</a>
                        </div>
                    </div>
        
                    <div className="container marketing">
                        {/* about us */}
                        <div className="featurette-divider leaves-bg">
                            <hr id="about-us" className="m-0" />
                        </div>
                        <div className="row featurette">
                            <h2 className="col-12 mb-5 p-3 rounded-pill feature-feader leaves-bg">Про нас</h2>

                            <div className="col-12 d-flex align-content-around flex-wrap mb-3">
                                <div className="p-2 feature-img bg-light map-preview-bg hide-on-lg hide-on-sm">
                                    <img className="w-100 h-100"  width="624" height="332" src={desktopPreview} alt="pc-preview" />
                                </div>
                            </div>

                            <div className="col-lg-4 d-flex align-content-around flex-wrap">
                                <div className="p-2 feature-img bg-light hide-on-md">
                                    <img className="w-100 h-100" width="304" height="540" src={iosPreview} alt="ios-preview" />
                                </div>
                            </div>

                            <div className="col-lg-8 d-flex align-content-around flex-wrap">
                                <div className="col-md-6 border-warning border-left feature-list-item bg-light">
                                    <h5 className="text-uppercase m-2" >Швидка та зручна інвентаризація дерев</h5>
                                    <p className="m-2">Відображення дерев на карті вашого міста. Присвоєння кожному дереву унікального номера</p>
                                </div>
                                <div className="col-md-6 border-warning border-left feature-list-item bg-light">
                                    <h5 className="text-uppercase m-2" >Історія дій для кожного дерева</h5>
                                    <p className="m-2">Історія всіх проведених дій відображається для кожного дерева</p>
                                </div>
                                <div className="col-md-6 border-warning border-left feature-list-item bg-light">
                                    <h5 className="text-uppercase m-2" >Зручне фільтрування дерев та експорт результатів</h5>
                                    <p className="m-2">Фільтрування дерев за багатьма параметрами чи за областю карти. Експорт отриманих результатів</p>
                                </div>
                                <div className="col-md-6 border-warning border-left feature-list-item bg-light">
                                    <h5 className="text-uppercase m-2" >Багаторівневі права доступу</h5>
                                    <p className="m-2">Доступ користувачів до функцій системи налаштовується адміністратором</p>
                                </div>
                                <div className="border-warning border-left feature-list-item bg-light mb-0">
                                    <h5 className="text-uppercase m-2" >Кросплатформенність, Адаптивність, Мобільність</h5>
                                    <p className="m-2">Працюйте з ПК, планшету чи смартфону, на будь-якій платформі з підтримкою Веб переглядача.</p>
                                </div>
                            </div>
                        </div>
                        
                        {/* customers */}
                        <div className="featurette-divider leaves-bg">
                            <hr id="customers" className="m-0" />
                        </div>
                        <div className="row featurette">
                            <h2 className="col-12 mb-5 p-3 rounded-pill feature-feader leaves-bg">Наші клієнти</h2>
                            <div className="m-auto text-center">
                                <div className="m-4 p-4 feature-img bg-light d-inline-block">
                                    <img className="m-4" width="93" height="120" src={lvivLogoSrc} alt="lviv logo" />
                                    <h4 className="feature-img-text mr-4">Львівська<br/>міська<br/>рада</h4>
                                </div>
                                <div className="m-4 p-4 feature-img bg-light d-inline-block flex-row align-items-center">
                                    <img className="m-4" width="106" height="120" src={lutskLogoSrc} alt="lutsk logo" />
                                    <h4 className="feature-img-text mr-4">Луцька<br/>міська<br/>рада</h4>
                                </div>
                                <div className="m-4 p-4 feature-img bg-light d-inline-block">
                                    <img className="m-4" width="97" height="120" src={rivneLogoSrc} alt="rivne logo" />
                                    <h4 className="feature-img-text mr-4">Рівненська<br/>міська<br/>рада</h4>
                                </div>
                                <div className="m-4 p-4 feature-img bg-light d-inline-block">
                                    <img className="m-4" width="97" height="120" src={dubnoLogoSrc} alt="dubno logo" />
                                    <h4 className="feature-img-text mr-4">Дубенська<br/>міська<br/>рада</h4>
                                </div>
                                <div className="m-4 p-4 feature-img bg-light d-inline-block">
                                    <img className="m-4" width="97" height="120" src={chortkivLogoSrc} alt="chortkiv logo" />
                                    <h4 className="feature-img-text mr-4">Чортківська<br/>міська<br/>рада</h4>
                                </div>
                            </div>
                        </div>
                        
                        {/* contacts */}
                        <div className="featurette-divider leaves-bg">
                            <hr id="contact-us" className="m-0" />
                        </div>
                        <div className="row featurette">
                            <h2 className="col-12 mb-5 p-3 rounded-pill feature-feader leaves-bg">Наші контакти</h2>
                            <div className="col-md-4 col-lg-4 text-center">
                                <div className="m-4">
                                    <a aria-label="Зателефонувати нам" href={`tel:${appConfig.contacts.phone}`} className="text-success">
                                        <i className="fas fa-4x fa-phone-square bg-light feature-img p-4"></i>
                                    </a>
                                </div>
                                <h4 className="text-secondary">Зателефонувати</h4>
                                <a href={`tel:${appConfig.contacts.phone}`}> {appConfig.contacts.phone}</a>
                            </div>
                            <div className="col-md-4 col-lg-4 text-center">
                                <div className="m-4">
                                    <a aria-label="Написати нам листа" href={`mailto:${appConfig.contacts.email}`} className="text-warning">
                                        <i className="fas fa-4x fa-envelope  bg-light feature-img p-4"></i>
                                    </a>
                                </div>
                                <h4 className="text-secondary">Написати нам</h4>
                                <a href={`mailto:${appConfig.contacts.email}`}>{appConfig.contacts.email}</a>
                            </div>
                            <div className="col-md-4 col-lg-4 text-center">
                                <div className="m-4">
                                    <a rel="noopener noreferrer" aria-label="Приєднатись на Facebook" target="_blank" href={appConfig.contacts.facebook}>
                                        <i className="fab fa-4x fa-facebook-square bg-light feature-img p-4"></i>
                                    </a>
                                </div>
                                <h4 className="text-secondary">Ми у Facebook</h4>
                                <a rel="noopener noreferrer" target="_blank" href={appConfig.contacts.facebook}>Підписатися</a>
                            </div>
                        </div>
                        <div className="featurette-divider leaves-bg"></div>
                    </div>
                    
                    <a href="#top" aria-label="back-to-top" className="btn btn-secondary shadow-lg fixed-bottom p-2 ml-auto m-4 mb-5 rounded-circle"
                        style={{opacity: opacityOnScroll, display: 'inline-table', left: 'auto', right: '0px !important'}}>
                        <i className="fas fa-3x fa-arrow-alt-circle-up" />
                    </a>
        
                    <LoginModal />
                </main>
                <Footer className="bg-dark" />
            </React.Fragment>
        );
    }
}
