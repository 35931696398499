import React from 'react';

declare type Props = {
    className?: string;
}

export const Footer: React.FC<Props> = (props) => (
    <div className={`fixed-bottom ${props.className || ''} text-white d-flex justify-content-center p-1 footer-shift`}>
        © Усі права захищено. Inspectree Павло Голубець. {new Date().getFullYear()}
    </div>
);
