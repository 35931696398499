import './App.css';
import 'react-toastify/dist/ReactToastify.css';

import React, { lazy, Suspense } from 'react';
import { Route, Link } from "react-router-dom";
import { useHistory } from 'react-router';
import ReactGA from 'react-ga';
import { toast } from 'react-toastify';

import Config from './app-config';
import { Logo } from './components/common/svg-component';
import { Footer } from './components/common/footer-component';
import { HomePage } from './components/home-page/home-page';
import UnhandledErrorsBoundry from './components/common/error-boundry';

const MapPage = lazy(() => import('./components/map-page/map-page').then(({ MapPage }) => ({ default: MapPage })));
const BarcodeScannerPage = lazy(() => import('./components/scanner-page/barcode-scanner-page'));

const MapFallbackPage: React.FC = () => {
  const spinnerFallback = (
    <div>
      <nav className="shadow-sm p-0 navbar navbar-expand-lg navbar-dark bg-dark-gradient fixed-top">
        <div className="d-flex flex-row ml-2 align-items-center">
          <Link className="navbar-brand mr-3" to="/">
              <Logo size={48} /><span className="brand-logo-text hide-on-sm">Inspectree</span>
          </Link>
        </div>
      </nav>
      
      <div className="text-center lazy-loader">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>

      <Footer className="bg-dark" />
    </div>);
  
  return (<Suspense fallback={spinnerFallback}><MapPage /></Suspense>);
}

const BarcodeScannerFallbackPage: React.FC = () => {
  const spinnerFallback = (
    <div className="bg-dark scanner-container">
      <nav className="shadow-sm p-0 navbar navbar-expand-lg navbar-dark bg-dark-gradient fixed-top">
        <div className="d-flex flex-row ml-2 align-items-center">
          <Link className="navbar-brand mr-3" to="/">
              <Logo size={48} /><span className="brand-logo-text hide-on-sm">Inspectree</span>
          </Link>
        </div>
      </nav>
      
      <div className="text-center lazy-loader">
        <div className="spinner-border text-light" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>

      <Footer />
    </div>);
  //@ts-ignore
  return (<Suspense fallback={spinnerFallback}><BarcodeScannerPage /></Suspense>);
};

export const App: React.FC = () => {
  const history = useHistory();

  toast.configure();
  ReactGA.initialize(Config.GA);
  history.listen(location => ReactGA.pageview(location.pathname));

  return (
    <UnhandledErrorsBoundry>
      <Route exact path="/" component={HomePage} />
      <Route path="/map" component={MapFallbackPage} />
      <Route path="/barcode-scanner" component={BarcodeScannerFallbackPage} />
    </UnhandledErrorsBoundry>
  );
};