import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Navbar, Nav, DropdownButton, Dropdown, Button, ButtonGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { MapData } from '../../redux/app-state';
import { Link } from "react-router-dom";
import lodash from 'lodash';

import './navigation-bar-component.css';
import {
    citiesSelector,
    modalsSelector,
    authoritySelector,
    isAnyFilterAppliedSelector,
    currentCitySelector,
    getTreesCount
} from '../../redux/selectors';
import {
    loggedOut,
    cityChanged,
    viewportChanged,
    updateListOfCities,
    toggleLoginDialog,
    toggleFiltersSidebar,
    toggleAddMarkerDialog,
} from '../../redux/actions';
import { CitiesResult } from '../../domain/types';

import { LatLng } from 'leaflet';
import { toast } from 'react-toastify';
import Search from './navigation-bar-search';
import { CREATE_TREE } from "../../types/permissions";
import { CoordinatesHelpers } from '../../domain/helpers';
import { getCities } from '../../services/city-api-service';
import { canExecute, isAuthorized } from '../../services/authorization-service';
import { GeoLocation } from '../../services/geolocation-service';
import { BarcodeICON, Logo } from './svg-component';
import { BarcodeButton } from './barcode-button-component';

const UserAuthComponent: React.FC = () => {
    const dispatch = useDispatch();
    const onLogin = useCallback(() => dispatch(toggleLoginDialog()), [dispatch]);
    const onLogout = useCallback(() => dispatch(loggedOut()), [dispatch]);
    const user = useSelector(authoritySelector);

    return (
        isAuthorized(user) ?
        <div className="ml-2 mr-4">
            <DropdownButton id="edit-tee-section" variant="outline-light" alignRight title={<React.Fragment><i className="fas fa-user-lock mr-2" />{user.name}</React.Fragment>}>
                <Link to="/" className="dropdown-item" onClick={onLogout}>Вийти</Link>
            </DropdownButton>
        </div>
        : <Link to="/" onClick={onLogin} className="ml-2 mr-4 btn btn-outline-light"><i className="fas fa-user mr-2" /> Увійти</ Link>
    );
};

const TreesCounterComponent: React.FC<{hideOnLarge:boolean}> = ({ hideOnLarge }) => {
    const treesCount = useSelector(getTreesCount);
    const filterApplied = useSelector(isAnyFilterAppliedSelector);

    return (
        <span className={`badge badge-light city-badge ${(hideOnLarge? 'hide-on-lg':'ml-2')}`}>
            <i className="fa fa-tree mr-2"/>
            {treesCount !== undefined ? treesCount : (<i className="spinner-border spinner-sz" />)}
            {treesCount !== undefined && filterApplied ? <i className="fa fa-check-circle ml-2 text-success"/> : null}
        </span>
    );
};

const MenuComponent: React.FC<{
    searchingMyLocation: boolean,
    onFindLocation: () => void,
    onMobileSearch: () => void,
    onAddAtCoorditantes: () => void
}> = ({ searchingMyLocation, onFindLocation, onMobileSearch, onAddAtCoorditantes }) => {    
    
    const location = useLocation();
    const isMapPage = useMemo(() => (/\/map/ig).test(location.pathname), [location]);
    const isMediaSupported = useMemo(() => Boolean(window.navigator.mediaDevices && typeof window.navigator.mediaDevices.getUserMedia === 'function'), []);

    const user = useSelector(authoritySelector);
    const city = useSelector(currentCitySelector);
    const { showAddPoint } = useSelector(modalsSelector);

    const dispatch = useDispatch();
    const onLogin = useCallback(() => dispatch(toggleLoginDialog()), [dispatch]);
    const onLogout = useCallback(() => dispatch(loggedOut()), [dispatch]);
    const onOpenFiltersSidebar = useCallback(() => dispatch(toggleFiltersSidebar()), [dispatch]);

    return (
        <DropdownButton id="app-menu"
            variant="outline-light"
            className="mr-3 city-badge hide-on-lg"
            title={<i className="fas fa-bars" />}
            alignRight
        >
            {GeoLocation.isSupported() && (
                <Dropdown.Item href="#" disabled={searchingMyLocation} onClick={onFindLocation}>
                    {<i className={`fas ${searchingMyLocation ? 'fa-spinner fa-pulse' : 'fa-street-view'} mr-2`} />} Моя Локація
                </Dropdown.Item>
                )}
            
            {!isMapPage && (<>
                {Boolean(city) && (<Link to="/map" className="dropdown-item"><i className="fas fa-map mr-2" />Мапа</Link>)}
                <Dropdown.Item href="#about-us"><i className="fas fa-info-circle mr-2" />Про нас</Dropdown.Item>
                <Dropdown.Item href="#customers"><i className="fas fa-user-tie mr-2" />Наші клієнти</Dropdown.Item>
                <Dropdown.Item href="#contact-us"><i className="fas fa-envelope mr-2" />Контакти</Dropdown.Item>
                <hr/>
            </>)}

            {isMapPage && (<>
                {canExecute(user, [CREATE_TREE]) && (<Dropdown.Item href="#"
                    className={`${showAddPoint?'forbiden-mouse':''}`}
                    onClick={onAddAtCoorditantes}>
                        <i className="fas fa-map-marker-alt mr-2"/> Додати дерево
                    </Dropdown.Item>)}
                {isMediaSupported && (
                    <Link className="dropdown-item" to="/barcode-scanner">
                            <span className="mr-2">
                                <BarcodeICON size={16} />
                            </span>Сканувати штрихкод
                    </Link>)}
                <Dropdown.Item href="#" onClick={onMobileSearch}><i className="fas fa-search mr-2" /> Пошук по номеру дерева</Dropdown.Item>
                <Dropdown.Item href="#" onClick={onOpenFiltersSidebar}><i className="fas fa-filter mr-2"/>Фільтри</Dropdown.Item>
            </>)}

            {isAuthorized(user)
                ? <Link to="/" className="dropdown-item" onClick={onLogout}><i className="fas fa-user-lock mr-2" />Вийти ({user.name})</Link>
                : <Link to="/" className="dropdown-item" onClick={onLogin}><i className="fas fa-user mr-2" />Увійти</Link>}
        </DropdownButton>
    );
};

const NavigationLinksComponent: React.FC<{
    searchingMyLocation: boolean,
    onFindLocation: () => void,
    onMobileSearch: () => void,
    onAddAtCoorditantes: () => void
}> = ({ searchingMyLocation, onFindLocation, onMobileSearch, onAddAtCoorditantes }) => {

    const location = useLocation();
    const isMapPage = useMemo(() => (/\/map/ig).test(location.pathname), [location]);

    const user = useSelector(authoritySelector);
    const city = useSelector(currentCitySelector);
    const { showAddPoint } = useSelector(modalsSelector);
    
    const dispatch = useDispatch();
    const onOpenFiltersSidebar = useCallback(() => dispatch(toggleFiltersSidebar()), [dispatch]);

    if(!isMapPage) {
        return (<>
            {GeoLocation.isSupported() && (
                <Button variant="outline-light" aria-label="Моя Локація" className="ml-2 mr-2"
                    onClick={onFindLocation}
                    disabled={searchingMyLocation}
                >
                    <i className={`fas ${searchingMyLocation ? 'fa-spinner fa-pulse' : 'fa-street-view'}`} />
                </Button>)}

            {Boolean(city) && <Link className="nav-link" to="/map">Мапа</Link>}
            <Nav.Link href="#about-us">Про нас</Nav.Link>
            <Nav.Link href="#customers">Наші клієнти</Nav.Link>
            <Nav.Link href="#contact-us">Контакти</Nav.Link>
        </>);
    }

    return (<>
        <ButtonGroup className="ml-2 mr-2">
            {GeoLocation.isSupported() && (
                <Button variant="outline-light" onClick={onFindLocation} disabled={searchingMyLocation}>
                    <i className={`fas ${searchingMyLocation ? 'fa-spinner fa-pulse' : 'fa-street-view'}`} />
                </Button>)}
            {canExecute(user, [CREATE_TREE]) && (
                <Button disabled={showAddPoint} variant="outline-light" onClick={onAddAtCoorditantes}>
                    <i className="fas fa-map-marker-alt" />
                </Button>)}
        </ButtonGroup>
        <Link className="add-tree-link nav-link" to="/map" onClick={onOpenFiltersSidebar}>
            Фільтри <TreesCounterComponent hideOnLarge={false} />
        </Link>
    </>);
};

export const NavigationBar: React.FC = () => {
    const [showMobileSearch, setShowMobileSearch] = useState<boolean>(false);
    const [searchingMyLocation, setSearchingMyLocation] = useState<boolean>(false);
    const isInitialRun = useRef(true);
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        if (isInitialRun.current) {
            isInitialRun.current = false;

            (async () => {
                const result = await getCities();
                if(result.success) {
                    const citiesResult = result.getValue() as CitiesResult;
                    dispatch(updateListOfCities(citiesResult.cities));
                }
            })();
        }
    }, [dispatch]);

    const city = useSelector(currentCitySelector);
    const onCityChanged = useCallback((newCity: string) => {
        if (city !== newCity) {
            dispatch(cityChanged(newCity));
        }

        history.push("/map");
    }, [city, history, dispatch]);

    const cities = useSelector(citiesSelector);
    const user = useSelector(authoritySelector);
    const onFindLocation = useCallback(async () => {
        setSearchingMyLocation(true);

        try {
            const location = await GeoLocation.locate();
        
            const providedCity = cities.find(_ => _.name === city) || {local_name:''};
            const foundInCity = cities.find(_ => {
                const cityBounds = CoordinatesHelpers.getApproximateBounds(_.center_point);
                return Boolean(cityBounds && cityBounds.contains(new LatLng(location.coords.latitude, location.coords.longitude)));
            });
            
            if (isAuthorized(user) && foundInCity && foundInCity.name !== city) {
                toast.info(`Неможливо відкрити розташування. Ви знаходитесь поза межами міста (${providedCity.local_name})`, { toastId: 'find-my-location' });
                return;
            }

            if (!foundInCity) {
                toast.info(`Неможливо відкрити розташування. Функціонал доступний лише в межах міст (${cities.map(_ => _.local_name).join(', ')})`, { toastId: 'find-my-location' });
                return;
            }
            
            lodash.delay(() => viewportChanged(CoordinatesHelpers.toViewport(MapData.maxZoom, location.coords.latitude, location.coords.longitude)), 1200);

            if(!isAuthorized(user)) {
                onCityChanged(foundInCity.name);
            }
        }
        catch(error) {
            toast.info(GeoLocation.getMessage(error));
        }
        finally {
            setSearchingMyLocation(false);
        }
    }, [user, city, cities, onCityChanged]);

    const { showAddPoint } = useSelector(modalsSelector);
    const onAddAtCoorditantes = useCallback(() => (!showAddPoint && dispatch(toggleAddMarkerDialog())), [showAddPoint, dispatch]);
    const onMobileSearch = useCallback(() => setShowMobileSearch(!showMobileSearch), [showMobileSearch]);
    const isMediaSupported = useMemo(() => Boolean(window.navigator.mediaDevices && typeof window.navigator.mediaDevices.getUserMedia === 'function'), []);

    const isMapPage = useMemo(() => (/\/map/ig).test(location.pathname), [location]);
    const localCityName = useMemo(() => (cities.find(_ => _.name  === city) || {local_name: ''}).local_name, [cities, city]);

    return (
        <Navbar bg="dark-gradient" expand="lg" fixed="top" variant="dark" className="shadow-sm p-0">
            <div className="d-flex flex-row flex-nowrap justify-content-between w-100">
                <div className="d-flex flex-row ml-2 align-items-center">
                    <Link className="navbar-brand mr-3" to="/">
                        <Logo size={48} /><span className="brand-logo-text hide-on-sm">Inspectree</span>
                    </Link>
                    {isAuthorized(user)
                        ? <span className="badge badge-warning city-badge hide-on-sm">{localCityName}</span>
                        : <DropdownButton id="select-city" className="city-badge align-content-center" variant="outline-light" title={localCityName || "Виберіть місто"}>
                            {cities.map(_ => <Dropdown.Item href="#" key={_.name} onClick={() => onCityChanged(_.name)}>{_.local_name}</Dropdown.Item>)}
                        </DropdownButton>}
                </div>
                <div className="d-flex align-items-center">{city && isMapPage && (<TreesCounterComponent hideOnLarge={true} />)}</div>
                <div className="d-flex flex-row align-items-center">
                    <MenuComponent searchingMyLocation={searchingMyLocation}
                        onFindLocation={onFindLocation}
                        onMobileSearch={onMobileSearch}
                        onAddAtCoorditantes={onAddAtCoorditantes} />
                </div>
                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-between">
                    <Nav className="mr-auto d-flex align-items-center">
                        <NavigationLinksComponent searchingMyLocation={searchingMyLocation}
                            onFindLocation={onFindLocation}
                            onMobileSearch={onMobileSearch}
                            onAddAtCoorditantes={onAddAtCoorditantes} />
                    </Nav>
                    <div className="d-flex flex-row align-items-center">
                        {isMapPage && isMediaSupported && (<BarcodeButton size="sm" variant="outline-light" onClick={() => history.push("/barcode-scanner")} />)}
                        {isMapPage && <Search />}
                        <UserAuthComponent />
                    </div>
                </Navbar.Collapse>
            </div>
            {isMapPage && showMobileSearch && (<div className="w-100 hide-on-lg"><Search /></div>)}
        </Navbar>
    );
};
