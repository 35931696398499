import { AnyAction, Reducer } from 'redux';
import { ModalsState } from '../app-state';
import {
    TOGGLE_ADD_MARKER_DIALOG,
    TOGGLE_MARKER_DETAILS,
    TOGGLE_LOGIN_DIALOG,
    TOGGLE_ADD_TREE_DIALOG,
    TOGGLE_INSPECT_DIALOG,
    TOGGLE_TREETASK_DIALOG,
    TOGGLE_CUT_TREE_DIALOG,
    TOGGLE_PLANT_TREE_DIALOG,
    TOGGLE_REPORT_ISSUE_DIALOG,
    TOGGLE_TREE_HISTORY_DIALOG,
    TOGGLE_REQUEST_FOR_ACTIONS
} from '../constants';

const initialState = new ModalsState();

const modalReducer: Reducer<ModalsState, AnyAction> = (state:ModalsState = initialState, action:AnyAction) => {
    let result = { ...state };

    switch(action.type) {
        case TOGGLE_LOGIN_DIALOG:
            result.showLogin = !state.showLogin;
            break;

        case TOGGLE_ADD_MARKER_DIALOG:
            result.showAddPoint = !state.showAddPoint;
            break;

        case TOGGLE_MARKER_DETAILS:
            result.showTreeDetails = !state.showTreeDetails;
            break;

        case TOGGLE_ADD_TREE_DIALOG:
            result.showAddTree = !state.showAddTree;
            break;

        case TOGGLE_TREE_HISTORY_DIALOG:
            result.showTreeHistory = action.show;
            break;

        case TOGGLE_INSPECT_DIALOG:
            result.showInspectTree = !state.showInspectTree;
            break;

        case TOGGLE_TREETASK_DIALOG:
            result.showTreeTask = !state.showTreeTask;
            break;

        case TOGGLE_CUT_TREE_DIALOG:
            result.showCutTree = !state.showCutTree;
            break;

        case TOGGLE_PLANT_TREE_DIALOG:
            result.showPlanTree = !state.showPlanTree;
            break;

        case TOGGLE_REPORT_ISSUE_DIALOG:
            result.showReportIssue = !state.showReportIssue;
            break;

        case TOGGLE_REQUEST_FOR_ACTIONS:
            result.showRequestForActions = !state.showRequestForActions;
            break;

        default: result = state; break;
    }

    return result;
}

export default modalReducer;
