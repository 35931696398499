import { toast } from 'react-toastify';
import { callAPI } from './api-client';
import { getResultFrom, Maybe } from './api-utilities';
import { RequestMethod, CitiesResult } from '../domain/types';
import { CoordinatesHelpers } from '../domain/helpers';
import { MapData } from '../redux/app-state';

const populateData = (cityResult: CitiesResult) => {
    for (let city of cityResult.cities) {
            
        city.min_zoom_level = city.min_zoom_level || MapData.minZoom;
        city.bounds = city.bounds || CoordinatesHelpers.getApproximateBounds(city.center_point);

        const center = CoordinatesHelpers.toLatLng(city.center_point.coordinates);
        city.view = CoordinatesHelpers.toViewport(city.min_zoom_level, center.lat, center.lng);
    }
    return cityResult;
};

export const getCities = async () => {
    const failureMsg = "Не вдалося завантажити Міста";
    try {
        const response = await callAPI({
            url: 'cities',
            method: RequestMethod.GET,
            headers: [['Cache-Control', 'max-age=604800']]
        });

        return Maybe.Success(
            populateData(
                await getResultFrom<CitiesResult>(response)
            )
        );
    }
    catch (error_reason) {
        console.warn(error_reason);
        toast.error(failureMsg);
    }

    return Maybe.Failed<CitiesResult>(failureMsg);
};