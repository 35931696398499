import React from 'react';
import { BarcodeICON } from './svg-component';
import { Button, ButtonProps } from 'react-bootstrap';

class State { highlighted: boolean = false; }
interface Props extends ButtonProps { onClick?: () => void; }

export class BarcodeButton extends React.PureComponent<Props, State> {
    constructor(props:Props) {
        super(props);
        this.state = new State();
    }

    render() {
        return (
        <Button {...this.props} onMouseEnter={() => this.setState({highlighted: true})} onMouseLeave={() => this.setState({highlighted: false})}>
            <BarcodeICON size={28} higlighted={this.state.highlighted} />
        </Button>);
    }
}