import { ApplicationState } from './app-state';
import { createSelector } from "reselect";
import { FeatureCollection, Point } from "geojson";
import pointsWithinPolygon from '@turf/points-within-polygon';
import lodash from 'lodash';
import { ITree, SelectOption } from '../domain/types';
import { OptionsType } from 'react-select/lib/types';
import { CutingReason, HealthDegradationReason, HealthImprovementReason, HealthStateMap, NeededActions, TaskTypes } from '../domain/enums';
import { mapToAdditionalValues, mapToMainViewValues } from '../services/tree-api-service';

export const modalsSelector = (state:ApplicationState) => state.modal;
export const sidebarsSelector = (state:ApplicationState) => state.sidebar;
export const authoritySelector = (state:ApplicationState) => state.authority;
export const currentCitySelector = (state:ApplicationState) => state.authority.city;
export const showTreeHistoryModalSelector = (state:ApplicationState) => state.modal.showTreeHistory;

export const filtersSelector = (state:ApplicationState) => state.filters;
export const isAnyFilterAppliedSelector = createSelector(
    filtersSelector,
    filters => filters.genus.length > 0
        || filters.species.length > 0
        || filters.district.length > 0
        || filters.selection.length > 0
        || filters.health_state.length > 0
        || Boolean(filters.max_age)
        || Boolean(filters.min_age)
        || Boolean(filters.isCut)
);
export const mapDataSelector = (state:ApplicationState) => state.mapData;
export const metadataSelector = (state:ApplicationState) => state.metadata;
export const getCurrentViewport = createSelector(mapDataSelector, (map) => map.viewport);
export const selectedTreeIdSelector = (state:ApplicationState) => state.mapData.selectedID;
export const isSelectedTreeFromSearchSelector = (state:ApplicationState) => state.mapData.isSelectedFromSearch;
export const detachedMarkersSelector = (state:ApplicationState) => state.mapData.detachedIDs;
export const isMovableSelector = (detachedIDs:string[], id:string) => detachedIDs.some(_ => _ === id);
export const selectedTreeIdentifierSelector = (state:ApplicationState) => (state.mapData.features.find(_ => String(_.id) === String(state.mapData.selectedID)) || {properties: {identifier: undefined}}).properties.identifier;
export const citiesSelector = (state:ApplicationState) => state.cities;

const drawingSelector = (state:ApplicationState) => state.drawing;
const progressSelector = (state:ApplicationState) => state.progress;
export const getProgressSelector = createSelector(progressSelector, (process) => process);
export const getLinePathSelector = createSelector(drawingSelector, (drawingState) => drawingState.path);
export const getSelectedAreasSelector = createSelector(drawingSelector, (drawingState) => drawingState.selectedAreas);
export const getTreesAlongPathSelector = createSelector(drawingSelector, (drawingState) => drawingState.treesAlongPath);
export const currentCityObjSelector = createSelector([citiesSelector, currentCitySelector], (cities, cityName) => cities.find(_ => _.name === cityName));
export const getTempTrees = createSelector(mapDataSelector, (map) => map.tempMarkers);
export const getTreesMarkers = createSelector(mapDataSelector, (map) => map.features);
export const getPreSavedTreeData = createSelector(mapDataSelector, (map) => {
    return map.preSaved ? {
        main: mapToMainViewValues(map.preSaved),
        additional: mapToAdditionalValues(map.preSaved)
    } : undefined;
});
export const isTreeHasBeenPlanted = createSelector(
    selectedTreeIdSelector, getTreesMarkers,
    (selectedID, markers) => markers.filter(_ => _.id === selectedID).some(_ => _.properties.is_planted)
);
export const getPickedTreeCoordinates = createSelector(
    getTempTrees, getTreesMarkers, selectedTreeIdSelector,
    (temp, trees, id) => temp.concat(trees).find(i => String(i.id) === String(id))?.geometry
);

export const getSelectedTrees = createSelector(getSelectedAreasSelector, mapDataSelector, (areas, map) => {
    const incomePointsCollection: FeatureCollection<Point, ITree> = { type: "FeatureCollection", features: map.features };
    let result = areas
        ? pointsWithinPolygon(incomePointsCollection, areas) as FeatureCollection<Point, ITree>
        : incomePointsCollection;

    result.features = lodash.uniqBy(result.features, 'id');
    
    return result;
});
export const getSelectedTreesIDs = createSelector(getSelectedAreasSelector, getSelectedTrees, (selection, treesCollection) => {
    return selection && selection.features.length
        ? treesCollection.features.map(f => String(f.id))
        : []
});
export const getTreesCount = createSelector(mapDataSelector, (map) => map.treesCount);

export const getLoginModalState = createSelector(modalsSelector, (modals) => modals.showLogin);
export const getAddTreeModalState = createSelector(modalsSelector, (modals) => modals.showAddTree);
export const getDetailsModalState = createSelector(modalsSelector, (modals) => modals.showTreeDetails);
export const getInspectionModalState = createSelector(modalsSelector, (modals) => modals.showInspectTree);
export const getPlantTreeModalState = createSelector(modalsSelector, (modals) => modals.showPlanTree);
export const getTasksTreeModalState = createSelector(modalsSelector, (modals) => modals.showTreeTask);
export const getCutTreeModalState = createSelector(modalsSelector, (modals) => modals.showCutTree);
export const getTreeHistoryModalState = createSelector(modalsSelector, (modals) => modals.showTreeHistory);
export const getReportIssueModalState = createSelector(modalsSelector, (modals) => modals.showReportIssue);
export const getAddByCoordinatesModalState = createSelector(modalsSelector, (modals) => modals.showAddPoint);
export const getRequestForActionsModalState = createSelector(modalsSelector, (modals) => modals.showRequestForActions);
export const getShowSidebarState = createSelector(sidebarsSelector, (sidebar) => sidebar.showFilters);

const getOptionsType: (mapStructure: Map<string, string>) => OptionsType<SelectOption>
= (map) => {
    return Array
        .from(map.keys())
        .map((key: string) => ({ label: String(map.get(key)), value: key.toLowerCase() }));
};

export const getGenusOptions = createSelector(metadataSelector, (metadata) => getOptionsType(metadata.genus));
export const getSpeciesOptions = createSelector(metadataSelector, (metadata) => getOptionsType(metadata.species));
export const getDistrictsOptions = createSelector(metadataSelector, (metadata) => getOptionsType(metadata.districts));
export const doesCityHasDistricts = createSelector(getDistrictsOptions, (districts) => districts.length > 0);
export const getHealthStatesOptions = createSelector(() => HealthStateMap, (map) => getOptionsType(map));
export const getNeededActionsOptions = createSelector(() => NeededActions, (map) => getOptionsType(map));
export const getTreeTaskOptions = createSelector(() => TaskTypes, (map) => getOptionsType(map));
export const getCutingReasonOptions = createSelector(() => CutingReason, (map) => getOptionsType(map));
export const getHealthDegradationReasonOptions = createSelector(() => HealthDegradationReason, (map) => getOptionsType(map));
export const getHealthImprovementReasonOptions = createSelector(() => HealthImprovementReason, (map) => getOptionsType(map));
export const getOwnerOptions = createSelector(metadataSelector, (metadata) => metadata.owners.map(e => ({ label: e, value: e.toLowerCase() })));
export const getSelectionsOptions = createSelector(metadataSelector, (metadata) =>
    Array
        .from(metadata.selection)
        .map((item) => {
            const key = item[0];
            const values = item[1];
            return values.map((v) => ({label: v, value: v.toLowerCase(), spiciesKey: key }))
        })
        .reduce((acc, val) => acc.concat(val), [])
        .sort((lt, rt)=> lt.value < rt.value ? -1 : (lt.value > rt.value ? 1 :0))
);