import { Filters } from "../app-state";
import { CLEAR_FILTERS, FILTER_DATA, USER_LOGGED_OUT } from "../constants";
import { Reducer, AnyAction } from "redux";

const initialState = new Filters();
const filterDataReducer: Reducer<Filters, AnyAction> = (state = initialState, action: AnyAction) => {
    let result: Filters;
    
    switch(action.type) {
        case FILTER_DATA:
            result = {...action.payload.data};
            break;

        case USER_LOGGED_OUT:
        case CLEAR_FILTERS:
            result = new Filters();
            break;

        default:
            result = state; break;
    }

    return result;
};

export default filterDataReducer;
