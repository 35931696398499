import { authorize } from './api-client';
import { RequestMethod } from '../domain/types';
import { AuthAction } from "../types/permissions";
import { getResultFrom, Maybe } from "./api-utilities";
import { UserAuthority } from '../redux/app-state';

export const isAuthorized: (user: UserAuthority) => boolean
= (user) => {
    return Boolean(user.accessToken) && Boolean(user.refreshToken);
};

export const canExecute: (
    user: UserAuthority,
    actions: AuthAction[],
    externalCheck?: () => boolean
) => boolean
= (user, actions, externalCheck) => {
    const actionsToChek = actions.join(',').toUpperCase();

    let result = isAuthorized(user)
        && user.actions.some(_ => actionsToChek.match(_.toUpperCase()));
    
    if (result && externalCheck) {
        result = externalCheck();
    }

    return result;
};

export const login: (id: string, secret: string) => Promise<Maybe<UserAuthority>>
= async (id, secret) => {
    try {
        const response = await authorize({
            url: 'users/tokens',
            method: RequestMethod.POST,
            data: { username: id, password: secret }
        });

        return Maybe.Success(
            await getResultFrom<UserAuthority>(response, (res) => ({
                ...res,
                accessToken: res.access_token,
                refreshToken: res.refresh_token
            }))
        );
    } catch (error_reason) {
        return Maybe.Failed("Електронна скринька або Пароль не коректні.");
    }
};