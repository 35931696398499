import { AnyAction, Reducer } from "redux";
import { TreeMetadata } from "../app-state";
import { UPDATE_METADATA, CITY_CHANGED, USER_LOGGED_IN, USER_LOGGED_OUT } from "../constants";

const initialState = new TreeMetadata();
const metadataReducer: Reducer<TreeMetadata, AnyAction> = (state: TreeMetadata = initialState, action:AnyAction) => {
    let result = {...state};
    
    switch(action.type) {
        case UPDATE_METADATA: {
            const { data } = action.payload;
            result = { ...data };
        };
        break;

        case CITY_CHANGED:
        case USER_LOGGED_IN:
        case USER_LOGGED_OUT:
            result = new TreeMetadata();
            break;

        default: result = state; break;
    }

    return result;
}

export default metadataReducer;
