export declare type AuthAction = CREATE_HEALTH_STATE | CREATE_ISSUES | CREATE_PLANTING_DETAILS
    | CREATE_TREE | CREATE_TREE_HOLDERS | CREATE_TREE_TASKS | CREATE_TREE_TASKS_CUTTING
    | CREATE_TREE_TASKS_INSPECTION | CSV_EXPORT | DELETE_TREE | GENERAL_ACTIONS
    | GET_ISSUES | GET_TREE_BY_POLYGONS | GET_TREE_HISTORY | GET_USERS
    | PATCH_TREE | PATCH_TREE_MOVE | TASK_BULK_UPDATE;

export const CREATE_HEALTH_STATE = "create-health-state";
type CREATE_HEALTH_STATE = typeof CREATE_HEALTH_STATE;

export const CREATE_ISSUES = "create-issues";
type CREATE_ISSUES = typeof CREATE_ISSUES;

export const CREATE_PLANTING_DETAILS = "create-planting-details";
type CREATE_PLANTING_DETAILS = typeof CREATE_PLANTING_DETAILS;

export const CREATE_TREE = "create-tree";
type CREATE_TREE = typeof CREATE_TREE;

export const CREATE_TREE_HOLDERS = "create-tree-holders";
type CREATE_TREE_HOLDERS = typeof CREATE_TREE_HOLDERS;

export const CREATE_TREE_TASKS = "create-tree-tasks";
type CREATE_TREE_TASKS = typeof CREATE_TREE_TASKS;

export const CREATE_TREE_TASKS_CUTTING = "create-tree-tasks-cutting";
type CREATE_TREE_TASKS_CUTTING = typeof CREATE_TREE_TASKS_CUTTING;

export const CREATE_TREE_TASKS_INSPECTION = "create-tree-tasks-inspection";
type CREATE_TREE_TASKS_INSPECTION = typeof CREATE_TREE_TASKS_INSPECTION;

export const CSV_EXPORT = "csv-export";
type CSV_EXPORT = typeof CSV_EXPORT;

export const DELETE_TREE = "delete-tree";
type DELETE_TREE = typeof DELETE_TREE;

export const GENERAL_ACTIONS = "general-actions";
type GENERAL_ACTIONS = typeof GENERAL_ACTIONS;

export const GET_ISSUES = "get-issues";
type GET_ISSUES = typeof GET_ISSUES;

export const GET_TREE_BY_POLYGONS = "get-tree-by-polygons";
type GET_TREE_BY_POLYGONS = typeof GET_TREE_BY_POLYGONS;

export const GET_TREE_HISTORY= "get-tree-history";
type GET_TREE_HISTORY = typeof GET_TREE_HISTORY;

export const GET_USERS = "get-users";
type GET_USERS = typeof GET_USERS;

export const PATCH_TREE = "patch-tree";
type PATCH_TREE = typeof PATCH_TREE;

export const PATCH_TREE_MOVE = "patch-tree-move";
type PATCH_TREE_MOVE = typeof PATCH_TREE_MOVE;

export const TASK_BULK_UPDATE = "bulk-update";
type TASK_BULK_UPDATE = typeof TASK_BULK_UPDATE;
