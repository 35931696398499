import { Feature, FeatureCollection, Point } from 'geojson';
import { LatLng, LatLngBounds } from 'leaflet';
import { toast } from 'react-toastify';
import ngeohash from 'ngeohash';
import lodash from 'lodash';
import { isAuthorized } from './authorization-service';
import { UserAuthority } from '../redux/app-state';
import { 
    callAPI,
    download,
    getAuthTokens,
    DownloadResult
} from './api-client';
import {
    Maybe,
    ViewFilter,
    getResultFrom,
    getTreesQueryString,
    getClustersQueryString,
    getTreesCountQueryString,
    getDownloadQueryString
} from './api-utilities';
import {
    HealthState,
    HistoryResult,
    HealthStateUpdate,
    ITree,
    ITreeCreate,
    ITreeUpdate,
    ITreeResponse,
    IFilterMapQuery,
    IClusterResponse,
    ITreeCountResponse,
    RequestMethod
} from '../domain/types';
import { MainViewValues } from '../components/map-page/modals/modal-edit-tree/main-props-view-component';
import { AdditionalViewValues } from '../components/map-page/modals/modal-edit-tree/additional-props-view-component';
import { toDOBstring, toNumber, toNumberString } from '../components/common/value-utils';

const transformClusterResponse: (source:IClusterResponse) => IClusterResponse
= (source) => {
    const clusters = Object.keys(source).map((geoHashKey : string) => {
        const geoPoint = ngeohash.decode(geoHashKey);
        const geoHashObj = (source as any)[geoHashKey];
        
        const geoBBox = ngeohash.decode_bbox(geoHashKey);
        const bbox = new LatLngBounds(new LatLng(geoBBox[0], geoBBox[1]), new LatLng(geoBBox[2], geoBBox[3]));
        const positions = [bbox.getSouthWest(), bbox.getNorthWest(), bbox.getNorthEast(), bbox.getSouthEast()];

        const center = new LatLng(geoPoint.latitude, geoPoint.longitude);
        const count = Object.keys(geoHashObj)
            .map((propKey: string) => Number(geoHashObj[propKey]["count"]))
            .reduce((prev, cur) => (prev + cur), 0);
        
        return { count, center, positions };
    });

    return { clusters };
};

const mergeTreesPages: (prev:ITreeResponse, current:ITreeResponse) => ITreeResponse
= (prev, current) => {
    console.warn(`duplicates found: [${prev.features.map(_ => {
        const duplicate = current.features.find(f => f.id === _.id) || {id: null};
        return duplicate.id;
    }).filter(_ => Boolean(_)).join()}]`);

    return {
        ...prev,
        features: prev.features.concat(current.features.filter(_ => prev.features.every(f => f.id !== _.id))),
        page: current.page
    };
}

const getTreesOnPage: (user:UserAuthority, query:string) => Promise<ITreeResponse>
= async (user, query) => {
    const url =`trees${query}`;
    const response = await callAPI({ url, method: RequestMethod.GET, auth: getAuthTokens(user) });

    return await getResultFrom<ITreeResponse>(response);
}

export const getTrees: (
    user:UserAuthority,
    userFilters:IFilterMapQuery,
    viewFilters:ViewFilter,
    dataHandler?:(trees:Feature<Point, ITree>[]) => void,
    progressHandler?:(progress:number) => void
) => Promise<Maybe<ITreeResponse>>
= async (user, userFilters, viewFilters, dataHandler, progressHandler) => {
    const failureMsg = "Не вдалося завантажити Дерева";

    const { city } = viewFilters;
    const cityParam = (!isAuthorized(user)? city : undefined);
    let pagesCount = 1;

    try {
        let query = getTreesQueryString(userFilters, {...viewFilters, city: cityParam});
        let result = await getTreesOnPage(user, query);

        pagesCount = Math.max(Math.ceil(result.total / result.pageSize), 1);
        const pagePercentage = 100 / pagesCount;
        
        let page = result.page + 1;
        if(page === pagesCount) {
            return Maybe.Success(result);
        }

        const progressFunc = progressHandler && (() => progressHandler(pagePercentage * (page + 1)));

        while(page < pagesCount) {
            if (dataHandler) {
                dataHandler(result.features);
            }

            if (progressFunc) {
                lodash.delay(progressFunc, 100 * page);
            }

            query = getTreesQueryString(userFilters, viewFilters, page);
            result = mergeTreesPages(result, await getTreesOnPage(user, query));
            page++;
        }

        return Maybe.Success(result);
    }
    catch (error_reason) {
        console.warn(error_reason);
        const  key = 'get-trees';
        if(!toast.isActive(key)) {
            toast.error(failureMsg, { toastId: key });
        }
    }
    finally {
        if (progressHandler) {
            lodash.delay(() => progressHandler(100), 100 * (pagesCount + 1));
        }
    }
    return Maybe.Failed<ITreeResponse>(failureMsg);
};

export const getTreesCount: (
    user:UserAuthority,
    userFilters:IFilterMapQuery,
    city:string,
    polygons?:FeatureCollection
) => Promise<Maybe<ITreeCountResponse>>
= async (user, userFilters, city, polygons) => {
    const failureMsg = "Не вдалося завантажити Дерева";
    const key = 'get-trees-count';
    const cityParam = (!isAuthorized(user)? city : undefined);
    try {
        const query = getTreesCountQueryString(userFilters, cityParam, polygons);
        const response = await callAPI({
            url: `trees/count${query}`,
            method: RequestMethod.GET,
            auth: getAuthTokens(user)
        });

        return Maybe.Success(
            await getResultFrom<ITreeCountResponse>(response)
        );
    }
    catch(error_reason) {
        console.warn(error_reason);
        if(!toast.isActive(key)) {
            toast.error(failureMsg, { toastId: key });
        }
    }
    return Maybe.Failed<ITreeCountResponse>(failureMsg);
};

export const downloadCSV: (
    user:UserAuthority,
    filters:IFilterMapQuery,
    city:string,
    polygons?:FeatureCollection
) => Promise<Maybe<DownloadResult>>
= async (user, filters, city, polygons) => {
    const failureMsg = "Помилка Завантаження";
    const key = 'export-to-csv';
    const cityParam = (!isAuthorized(user)? city : undefined);
    try {
        const downloadResult = await download(
            `trees${getDownloadQueryString(filters, cityParam, polygons)}`,
            getAuthTokens(user)
        );

        return Maybe.Success(downloadResult);
    }
    catch(error_reason) {
        console.warn(error_reason);
        if(!toast.isActive(key)) {
            toast.error(failureMsg, { toastId: key });
        }
    }
    return Maybe.Failed<DownloadResult>(failureMsg);
};

export const searchTreeById: (user:UserAuthority, query:string, city:string) => Promise<Maybe<ITreeResponse>>
= async (user, query, city) => {
    const failureMsg = "Не вдалося завантажити результати пошуку";
    try {
        const params = { identifier: query, city, clustered: false };
        
        // @ts-ignore
        const queryString = new URLSearchParams(params).toString();
        const response = await callAPI({
            url: `trees?${queryString}`,
            method: RequestMethod.GET,
            auth: getAuthTokens(user)
        });

        return Maybe.Success(
            await getResultFrom<ITreeResponse>(response)
        );
    }
    catch(error_reason) {
        console.warn(error_reason);
    }
    return Maybe.Failed<ITreeResponse>(failureMsg);
};

function normalizeTrunkSize(main: MainViewValues) {
    const circumference = main.trunkDiameter;
    const multistamb = main.multistamb;

    if (circumference && multistamb) {
        const safeMultistamb = multistamb.filter(_ => Boolean(_));

        if (safeMultistamb.length > 0) {
            const greatestMultistamb = safeMultistamb.reduce((prev, curr) => prev > curr ? prev : curr, 0);

            if (circumference < greatestMultistamb) {
                const idx = safeMultistamb.findIndex((val) => val === greatestMultistamb);
                safeMultistamb[idx] = circumference;

                main.trunkDiameter = greatestMultistamb;
            }

            main.multistamb = [...safeMultistamb.sort((left, right) => right - left)];
        }
    }
}

export function mapToCreateTree(main: MainViewValues, additional: AdditionalViewValues) : ITreeCreate {
    normalizeTrunkSize(main);
    return {
        genus: main.genus || '',
        species: main.specie || '',
        selection: main.selection || '',
        district: main.district || '',
        tree_holder: main.owner || '',
        health_state: main.healthState || '',
        needed_actions: main.neededActions || [],
        dob: toDOBstring(Number(main.age)) as string,
        identifier: additional.identifier,
        attributes: {
            isMonumentOfNature: main.isMonumentOfNature,
            height: additional.height,
            crown_diameter: additional.crownSize,
            trunk_diameter: main.trunkDiameter,
            multistamb: main.multistamb,
            east_to_west_projection: additional.w2e,
            north_to_south_projection: additional.n2s,
            deviation_from_vertical_axis: additional.deviation,
            percentage_of_crown_damaged_by_mistletoe: additional.mistletoePercents,
            pit_area: additional.pitArea,
            distance_to_buildings: additional.distance2buildings,
            has_bird_nests: additional.hasNests,
        }
    };
};

export function mapToUpdateTree(update: {id: string, main: MainViewValues, dimensions: AdditionalViewValues}): ITreeUpdate {
    return {
        uid: update.id,

        available: undefined,
        died_on: undefined,
        dying_reason: undefined,
        attributes: undefined,
        comment: undefined,

        ...mapToCreateTree(update.main, update.dimensions),
    };
};

export const mapToMainViewValues: (tree?: Feature<Point, ITree>) => MainViewValues
= (tree) => {
    const { isMonumentOfNature = undefined, trunk_diameter = undefined, multistamb = undefined } = {...tree?.properties.attributes};
    const { genus, species, selection, age, current_state, needed_actions = [], current_holder, district  } = {...tree?.properties};

    return {
        genus: genus,
        specie: species,
        selection: selection,
        healthState: current_state,
        age: toNumberString(age),
        trunkDiameter: toNumber(trunk_diameter),
        multistamb: multistamb,
        isMonumentOfNature: isMonumentOfNature,
        neededActions: needed_actions,
        district: district,
        owner: current_holder
    };
};

export const mapToAdditionalValues: (tree?: Feature<Point, ITree>) => AdditionalViewValues
= (tree) => {
    const { identifier } = { ...tree?.properties };
    const attr = { ...tree?.properties.attributes };

    return {
        height: attr.height,
        pitArea: attr.pit_area,
        identifier: identifier,
        hasNests: attr.has_bird_nests,
        crownSize: attr.crown_diameter,
        w2e: attr.east_to_west_projection,
        n2s: attr.north_to_south_projection,
        deviation: attr.deviation_from_vertical_axis,
        distance2buildings: attr.distance_to_buildings,
        mistletoePercents: attr.percentage_of_crown_damaged_by_mistletoe,
    };
};

export const addTree: (
    user:UserAuthority,
    data: ITreeCreate,
    point: Point
) => Promise<Maybe<Feature<Point, ITree>>>
= async (user, data, point) => {
    let failureMsg = "Не вдалось додати Дерево";
    try {
        const response = await callAPI({
            url: 'trees',
            method: RequestMethod.POST,
            data: {
                attributes: {},
                comment: null,
                point: point,
                ...data
            },
            auth: getAuthTokens(user)
        });

        if(response.status === 409) {
            failureMsg = `Дерево з ідентифікатором '${data.identifier}' вже існує`;
        }

        return Maybe.Success(
            await getResultFrom<Feature<Point, ITree>>(response)
        );
    } catch (error_reason) {
        console.warn(error_reason);
        const key = `add-tree`;
        if(!toast.isActive(key)) {
            toast.error(
                (/duplication/ig.test(error_reason.message)? `Дерево за цими координатамм вже існує.` : failureMsg),
                { toastId: key }
            );
        }
    }
    return Maybe.Failed<Feature<Point, ITree>>(failureMsg);
};

export const updateTree: (user:UserAuthority, data: ITreeUpdate) => Promise<Maybe<Feature<Point, ITree>>>
= async (user, data) => {
    let failureMsg = "Не вдалось оновити дані Дерева";
    try {
        const response = await callAPI({
            url: `trees/${data.uid}`,
            method: RequestMethod.PATCH,
            data,
            auth: getAuthTokens(user)
         });

        if(response.status === 409) {
            failureMsg = `Дерево з ідентифікатором '${data.identifier}' вже існує`;
        }

        return Maybe.Success(
            await getResultFrom<Feature<Point, ITree>>(response)
        );
    }
    catch (error_reason) {
        console.warn(error_reason);
        const key = `update-tree-${data.uid}`;
        if(!toast.isActive(key)) {
            toast.error(failureMsg, { toastId: key });
        }
    }
    return Maybe.Failed<Feature<Point, ITree>>(failureMsg);
};

export const updateTreeHealth: (
    user:UserAuthority,
    uid: string,
    data: HealthStateUpdate
) =>Promise<Maybe<HealthState>>
= async (user, uid, data) => {
    const failureMsg = `Не вдалось змінити Статус`;
    try {
        const response = await callAPI({
            url: `trees/${uid}/health_states`,
            method: RequestMethod.POST,
            data: data,
            auth: getAuthTokens(user)
        });

        return Maybe.Success(
            await getResultFrom<HealthState>(response)
        );
    } catch(error_reason) {
        console.warn(error_reason);
        const key = `update-tree-${uid}-health-state`;
        if(!toast.isActive(key)) {
            toast.error(failureMsg, { toastId: key });
        }
    }
    return Maybe.Failed<HealthState>(failureMsg);
};

export const updateTreeHolder: (
    user:UserAuthority,
    uid:string,
    holder:string
) => Promise<Maybe<void>>
= async (user, uid, holder) => {
    const failureMsg = `Не вдалось змінити Балансоутримувача`;
    try {
        const response = await callAPI({
            url: `trees/${uid}/holder`,
            method: RequestMethod.POST,
            data: { name: holder },
            auth: getAuthTokens(user)
        });

        return Maybe.Success(
            await getResultFrom<void>(response)
        );
    } catch(error_reason) {
        console.warn(error_reason);
        const key = `update-tree-${uid}-holder`;
        if(!toast.isActive(key)) {
            toast.error(failureMsg, { toastId: key });
        }
    }
    return Maybe.Failed<void>(failureMsg);
};

export const updateTreeLocation: (
    user: UserAuthority,
    id: string,
    point: GeoJSON.Point
) => Promise<Maybe<ITree>>
= async (user, id, point) => {
    const failureMsg = `Не вдалось змінити Координати`;
    try {
        const response = await callAPI({
            url: `trees/${id}`,
            method: RequestMethod.PATCH,
            data: { point },
            auth: getAuthTokens(user)
        });

        return Maybe.Success(
            await getResultFrom<ITree>(response)
        );
    } catch(error_reason) {
        console.warn(error_reason);
        const key = `update-tree-${id}-location`;
        if(!toast.isActive(key)) {
            toast.error(failureMsg, { toastId: key });
        }
    }
    return Maybe.Failed<ITree>(failureMsg);
};

export const getTreeHistory: (
    user:UserAuthority,
    uid:string
) =>Promise<Maybe<HistoryResult>>
= async (user, uid) => {
    const failureMsg = "Не Вдала спроба завантаження Історію дерева";
    try {
        const response = await callAPI({
            url: `trees/${uid}/history`,
            method: RequestMethod.GET,
            auth: getAuthTokens(user)
        });

        return Maybe.Success(
            await getResultFrom<HistoryResult>(response)
        );
    }
    catch (error_reason) {
        console.warn(error_reason);

        const key = `get-history-${uid}`;
        if(!toast.isActive(key)) {
            toast.error(failureMsg, { toastId: key });
        }
    }
    return Maybe.Failed<HistoryResult>(failureMsg);
};

export const getTree: (user: UserAuthority, id: string) => Promise<Maybe<Feature<Point, ITree>>>
= async (user, id) =>  {
    const failureMsg = "Hе вдалося завантажити відомості про Дерево";
    try {
        const response = await callAPI({
            url: `trees/${id}`,
            method: RequestMethod.GET,
            auth: getAuthTokens(user)
        });

        return Maybe.Success(
            await getResultFrom<Feature<Point, ITree>>(response)
        );
    }
    catch (error_reason) {
        console.warn(error_reason);
        const key = `get-tree-${id}`;
        if(!toast.isActive(key)) {
            toast.error(failureMsg, { toastId: key });
        }
    }
    return Maybe.Failed<Feature<Point, ITree>>(failureMsg);
};

export const getClusters: (
    user:UserAuthority,
    userFilters:IFilterMapQuery,
    viewFilters:ViewFilter
) => Promise<Maybe<IClusterResponse>>
= async (user, userFilters, viewFilters) => {
    const failureMsg = "Не вдалося завантажити Дерева";
    const { city } = viewFilters;
    const cityParam = (!isAuthorized(user)? city : undefined);
    try {
        const query = getClustersQueryString(userFilters, {...viewFilters, city: cityParam});
        const response = await callAPI({
            url: `trees${query}`,
            method: RequestMethod.GET,
            auth: getAuthTokens(user)
        });

        return Maybe.Success(
            transformClusterResponse(
                await getResultFrom<IClusterResponse>(response)
            )
        );
    }
    catch (error_reason) {
        console.warn(error_reason);
        const key = 'get-clustered-trees';
        if(!toast.isActive(key)) {
            toast.error(failureMsg, { toastId: key });
        }
    }
    return Maybe.Failed<IClusterResponse>(failureMsg);
};