import { Position, Feature, Geometry, Polygon, LineString, Point as getPoint } from 'geojson';
import { LatLng, LatLngBounds, Point } from "leaflet";
import { Viewport } from 'react-leaflet';

class AgeHelpers {

    private static isSingular(value: number) {
        const lastDigit = (value % 10);
        const latTwoDigits = (value % 100);

        return lastDigit === 1 && latTwoDigits !== 11;
    }

    private static choosePluralForm(value: number) {
        const lastDigit = (value % 10);
        const latTwoDigits = (value % 100);
        
        const expected = [2,3,4];
        const exceptions = [11,12,13,14];

        return exceptions.every(_ => latTwoDigits !== _) && expected.some(_ => _ === lastDigit) ? 'Роки' : 'Років';
    }

    static toLabelString(value: number) {
        const absoluteValue = Math.abs(value);
        
        return !this.isSingular(absoluteValue) || isNaN(absoluteValue)
            ? this.choosePluralForm(absoluteValue)
            : 'Рік';
    }
}

class CoordinatesHelpers {
    private static _latitudeId = 1;
    private static _longtitudeId = 0;

    public static toLatLng(coordinate: Position) {
        return new LatLng(coordinate[this._latitudeId],coordinate[this._longtitudeId]);
    }

    public static toPosition(coordinate: LatLng): Position {
        return [coordinate.lng, coordinate.lat];
    }
    
    public static toPoint(coordinate: LatLng): Point {
        return new Point(coordinate.lat, coordinate.lng);
    }

    public static toViewport(zoom: number, latitude: number, longtitude: number): Viewport {
        return { zoom, center: [latitude, longtitude] };
    }

    public static getApproximateBounds(point?: getPoint): LatLngBounds | undefined {
        return point && this.toLatLng(point.coordinates).toBounds(30*1000);
    }

    public static isInside(bounds: LatLngBounds, viewport: Viewport) {
        const center = viewport.center || [-0,-0];
        return bounds.contains(new LatLng(center[0], center[1]));
    }
}

class GeometryHelper {
    public static toPolygon(bound: LatLngBounds): Polygon {
        const result: Polygon = {
            type: "Polygon",
            coordinates: [[
                CoordinatesHelpers.toPosition(bound.getNorthWest()),
                CoordinatesHelpers.toPosition(bound.getNorthEast()),
                CoordinatesHelpers.toPosition(bound.getSouthEast()),
                CoordinatesHelpers.toPosition(bound.getSouthWest()),
                CoordinatesHelpers.toPosition(bound.getNorthWest())
            ]]
        };
        
        return result;
    }
    
    public static toPositions(feature: Feature<Geometry, any>) {
        if (feature.geometry.type === "Polygon") {
            const polygon = feature as Feature<Polygon, any>;
            return polygon.geometry.coordinates.map(coordinates => coordinates.map(position => CoordinatesHelpers.toLatLng(position)));
        }

        if (feature.geometry.type === "LineString") {
            const polygon = feature as Feature<LineString, any>;
            return polygon.geometry.coordinates.map(position => CoordinatesHelpers.toLatLng(position));
        }

        throw new Error(`Method doesn't support the '${feature.geometry.type}' Geometry yet.`);
    }
}

export { AgeHelpers, CoordinatesHelpers, GeometryHelper };