import { getCities } from './city-api-service';
import { CoordinatesHelpers } from '../domain/helpers';
import { Viewport } from 'react-leaflet';

export class GeoLocation {
    private static readonly geolocationOptions = { enableHighAccuracy: true, timeout: 5000 };
    private static readonly codesMap: Map<number, string> = new Map([
        [1, 'У дозволі відмовлено. (Перевірте налаштування геолокації в налаштулваннях переглядача)'],
        [2, 'Позиція недоступна. (Можливо ви знаходитесь не в зоні радіусу дії GPS мереж)'],
        [3, 'Не вдалось визначити координати. (Спробуйте згодом)']
    ]);

    static isSupported(): boolean {
        return Boolean(navigator.geolocation);
    }

    static locate(): Promise<any> {
        return this.isSupported()
                ? new Promise((res, rej) => navigator.geolocation.getCurrentPosition(res, rej, this.geolocationOptions)) 
                : Promise.reject("Your client doesn't support or doesn't allow to get geolocation data.");
    }
    
    static getMessage(error: any) {
        return this.codesMap.get(error.code);
    }

    static async locateBy(city?:string) : Promise<Viewport> {
        
        const citiesData = await getCities();
        if (citiesData.success) {
            var cityFound = citiesData
                .getValue()
                .cities
                .find(c => RegExp(String(city),'ig').test(c.name));

            if (cityFound) {
                var latLng = CoordinatesHelpers.toLatLng(cityFound.center_point.coordinates);
                return Promise.resolve(CoordinatesHelpers.toViewport(cityFound.min_zoom_level, latLng.lat, latLng.lng));
            }
        }

        return Promise.reject(`Can't find city '${city}' coordinates`);
    }
}
