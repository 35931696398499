import { TreeCutReason, TaskType, TreeAction } from "./types";

export const HealthStateMap = new Map<string, string>([
    ['very_good', 'Ідеальний'],
    ['good', 'Добрий'],
    ['ok', 'Задовільний'],
    ['bad', 'Незадовільний'],
    ['dead', 'Мертвий'],
]);

export const HealthImprovementReason = new Map<string, string>([
    ["treatment", "Лікування"],
    ["fastening", "Підв’язка"],
    ["watering", "Полив"],
    ["fertilizers", "Внесення добрив"],
    ["branch_cutting", "Санітарна обрізка"],
    ["god_knows_why", "Невідомо"]
]);

export const HealthDegradationReason = new Map<string, string>([
    ["crashed_by_wind", "Пошкодження від вітру"],
    ["vandalism", "Вандалізм"],
    ["incorrectly_planted", "Неправильна посадка"],
    ["misplaced", "Неправильне розміщення"],
    ["drought", "Нестача вологи"],
    ["too_much_water", "Перезволоження"],
    ["fastening", "Неправильна підв’язка"],
    ["damaged_by_grass_cut", "Пошкодження при косінні трави"],
    ["salt", "Засолення грунту"],
    ["illness", "Хвороба"],
    ["branch_cutting", "Неправильна обрізка"],
    ["god_knows_why", "Невідомо"],
    ["age", "Старість"],
    ["too_much_water", "Перезволоження"],
]);

export const NeededActions = new Map<string, string>([
    ['final_cutting', 'Зрізка дерева'],
    ['fertilizers', 'Внесення добрив'],
    ['watering', 'Полив'],
    ['mulching', 'Мульчування'],
    ['fastening', 'Підвязка'],
    ['pest_treatment', 'Обробка від шкідників'],
    ['planting_correction', 'Корегування посадки'],
    ['branch_cutting_correction', 'Корегування зрізів гілок'],
    ['trunk_treatment', 'Лікування пошкоджень стовбура'],
    ['sanitary_pruning', 'Санітарна обрізка'],
    ['forming_pruning', 'Формувальна обрізка'],
    ['road_signs_visibility', 'Забезпечення видимості дорожних знаків'],
    ['air_cables_safety', 'Убезпечення повітряних кабелів'],
    ['insolation', 'Забезпечення інсоляції будівлі'],
    ['branch_fixation', 'Фіксація гілок крони'],
    ['dismantling_fastening_pillars', 'Демонтаж опор для підвязки'],
    ['mantling_fastening_pillars', 'Монтаж опор для підвязки'],
    ['dismantling_trunk_protection', 'Демонтаж захисту стовбура'],
    ['mantling_trunk_protection', 'Монтаж захисту стовбура']
]);

export const TaskTypes = new Map<TaskType, string>([
    ["fertilizers", "Внесення добрив"],
    ["watering", "Полив"],
    ["mulching", "Мульчування"],
    ["fastening", "Підв'язка"],
    ["pest_treatment", "Обробка від шкідників"],
    ["planting_correction", "Корекція посадки"],
    ["branch_cutting_correction", "Корегування зрізів гілок"],
    ["trunk_treatment", "Лікування пошкоджень стовбура"],
    ["sanitary_pruning", "Санітарна обрізка"],
    ["forming_pruning", "Формувальна обрізка"],
    ["road_signs_visibility", "Забезпечення видимості дорожніх знаків"],
    ["air_cables_safety", "Убезпечення повітряних кабелів"],
    ["insolation", "Забезпечення інсоляції будинку"],
    ["branch_fixation", "Фіксація гілок крони"],
    ["dismantling_fastening_pillars", "Демонтаж опор для підвязки"],
    ["mantling_fastening_pillars", "Монтаж опор для підвязки"],
    ["dismantling_trunk_protection", "Демонтаж захисту стовбура"],
    ["mantling_trunk_protection", "Монтаж захисту стовбура"]
]);

export const TreeActions = new Map<TreeAction, string>([
    ...TaskTypes,
    ["planting", "Висадка"],
    ["cutting", "Зрізка"],
]);

export const CutingReason = new Map<TreeCutReason, string>([
    ["illegal_cutting", "Нелегальна Зрізка"],
    ["city_building_reasons", "Будівельні роботи"],
    ["death", "Дерево мертве"],
    ["illness", "Поганий стан"],
    ["building_insolation", "Забезпечення інсоляції будівлі"],
    ["building_damage", "Дерево пошкоджує будівлю"],
    ["growing_on_communications", "Дерево росте на комунікаціях"],
    ["road_signs_visibility", "Забезпечення видимості дорожних знаків"],
]);

export const HealthChangeReasons = new Map<string | TreeCutReason, string>([
    ...HealthImprovementReason,
    ...HealthDegradationReason,
    ...CutingReason,
]);

export const IssueCategory = new Map<string, string>([
    ['road_signs_visibility', 'Забезпечення видимості дорожних знаків'],
    ['insolation', 'Забезпечення інсоляції будівлі'],
    ["watering", "Полив"],
    ["branch_cutting", "Обрізка гілок"],
    ['final_cutting', 'Зрізка дерева'],
    ['notify_that_tree_is_valuable', 'Дерево Бодгі']
]);
