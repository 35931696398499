import { HealthStateMap, NeededActions } from './enums';
import { OptionsType } from 'react-select/lib/types';
import { LatLng, LatLngBounds, LatLngExpression } from 'leaflet';
import { Feature, Point } from 'geojson';
import { Option } from 'react-select/lib/filters';
import { Viewport } from 'react-leaflet';

export enum RequestMethod {
    DELETE = 'DELETE',
    GET = 'GET',
    OPTIONS = 'OPTIONS',
    PUT = 'PUT',
    POST = 'POST',
    PATCH = 'PATCH',
    HEAD = 'HEAD',
}

export declare type SelectOption = { label: string, value: string }

export interface ITreeBase {
    uid: string;
    available?: boolean;
    died_on?: string;
    dying_reason?: string;
}

export interface ITreeExtended {
    attributes?: any;
    comment?: string;
    identifier?: string;
}

export interface ITreeCreate extends ITreeExtended {
    district: string;
    dob: string;
    genus: string;
    species: string;
    selection: string;
    health_state: string;
    tree_holder: string;
    needed_actions: string[];
}

export interface ITreeUpdate extends ITreeBase, ITreeCreate, ITreeExtended {
    point?: Point;
}

export interface ITree extends ITreeBase, ITreeCreate {
    age: number;
    created_at: string;
    current_holder: string;
    current_state: string;
    is_cut: boolean;
    is_dead: boolean;
    is_planted: boolean;
    is_monument_of_nature: boolean;
    updated_at: string;
    updated_by: string;
}

export interface ICluster {
    count: number;
    center: LatLng;
    positions: LatLngExpression[];
}

export interface IClusterResponse {
    clusters: ICluster[];
}

export interface ITreeResponse {
    features: Feature<Point, ITree>[];

    pageSize: number;
    total: number;
    page: number;
}

export interface IUpdateTrees {
    features?: Feature<Point, ITree>[];
    clusters?: ICluster[];
}

export interface ITreeCountResponse {
    count: number;
}

export interface ITreeMetadata {
    genus: Map<string,string>;
    species: Map<string,string>;
    owners: Array<string>;
    districts: Map<string,string>;
    selection: Map<string,string[]>;
}

export class TreeDataOptions {
    constructor(metadata: ITreeMetadata) {
        this.genusOptions = TreeDataOptions.getOptionsType(metadata.genus);
        this.speciesOptions = TreeDataOptions.getOptionsType(metadata.species);
        this.districtsOptions = TreeDataOptions.getOptionsType(metadata.districts);
        this.healthStatesOptions = TreeDataOptions.getOptionsType(HealthStateMap);
        this.neededActionsOptions = TreeDataOptions.getOptionsType(NeededActions);
        this.ownersOptions = metadata.owners.map(e => ({ label: e, value: e.toLowerCase() }));

        this.selectionsOptions = Array.from(metadata.selection)
             .map((item) => {
                 const key = item[0];
                 const values = item[1];
                 return values.map((v) => ({label: v, value: v.toLowerCase(), spiciesKey: key }))
             })
             .reduce((acc, val) => acc.concat(val), [])
             .sort((lt, rt)=> lt.value < rt.value ? -1 : (lt.value > rt.value ? 1 :0));
    }

    genusOptions: OptionsType<SelectOption>;
    speciesOptions: OptionsType<SelectOption>;
    selectionsOptions: OptionsType<{label:string, value:string, spiciesKey:string}>;
    districtsOptions: OptionsType<SelectOption>;
    healthStatesOptions: OptionsType<SelectOption>;
    ownersOptions: OptionsType<SelectOption>;
    neededActionsOptions: OptionsType<SelectOption>;

    static getOptionsType(mapStructure: Map<string, string>) : OptionsType<SelectOption> {
        return Array.from(mapStructure.keys())
                    .map((key: string) => ({ label: String(mapStructure.get(key)), value: key.toLowerCase() }));
    }
}

export class UID {
    public static TempPrefix = 'temp-id--';

    private _id: string;
    private constructor(id: string) {
        this._id = id;
    }

    static create(index?: number): UID {
        return new UID(`${UID.TempPrefix}${Date.now()}${index}`);
    }

    isTemporary(): boolean {
        return this._id.startsWith(UID.TempPrefix);
    }

    static isTemporary(id: string): boolean {
        return id.startsWith(UID.TempPrefix);
    }

    toString(): string {
        return this._id;
    }
}

export interface IProgressIndicator {
    show: boolean;
    message?: string;
    progress: number;
}

export interface IFilterMapQuery {
    genus?: string[];
    district?: string[];
    selection?: string[];
    health_state?: string[];
    needed_actions?: string[];
    species?: string[];
    max_age?: string;
    min_age?: string;
    isCut?: boolean;
}

export declare type BaseTreeTask = {
    according_to?: string;
    conducted_at: string;
    conducted_by?: string;
    description?: string;
    is_illegal:boolean;
}

export declare type ShortTreeTask = {
    at: string;
    a_to?: string;
    c_at: string;
    c_by?: string;
    d?: string;
    ii: boolean;
    tt: TreeAction;
}

export declare type TaskType = "fertilizers" | "inspections" | "watering"
    | "mulching" | "fastening" | "pest_treatment" | "planting_correction"
    | "branch_cutting_correction" | "trunk_treatment" | "sanitary_pruning"
    | "forming_pruning" | "road_signs_visibility" | "air_cables_safety"
    | "insolation" | "branch_fixation" | "dismantling_fastening_pillars"
    | "mantling_fastening_pillars" | "dismantling_trunk_protection"
    | "mantling_trunk_protection";

export declare type TreeAction = TaskType | "planting" | "cutting";

export declare type TreeHealthState = "very_good" | "good" | "ok" | "bad" | "dead";

export interface ITreeTask extends BaseTreeTask {
    tree_task: TaskType;
}

export interface ITask extends ITreeTask {
    tree_uid: string;
}

export interface IPlantTree extends BaseTreeTask {
}

export interface IPlantTreeInfo extends BaseTreeTask {
    planted_at: string;
}

export declare type TreeCutReason = "illegal_cutting" | "city_building_reasons" | "death"
    | "illness" | "building_insolation" | "building_damage" | "growing_on_communications"
    | "road_signs_visibility";

export declare type BaseCutTree = {
    according_to?: string;
    conducted_by?: string;
    cutting_reason: TreeCutReason;
    description?: string;
    is_illegal: boolean;
}

export interface ICutTree extends BaseCutTree {
    conducted_at: string;
}

export interface ICutTreeInfo extends BaseCutTree {
    cut_at: string;
}

export declare type HealthStateUpdate = {
    description: string;
    new_state: string;
    state_change_reason: string;
}

export declare type HealthState = {
    s: TreeHealthState,
    tree_uid: string,
    u_at: string;
    scr: string;
    d: string;
};

export declare type HealthResult = {
    health_states: HealthState[];
}

export declare type HistoryResult = {
    history: ITree[];
}

export declare type TreeTasksResult = {
    tree_tasks: ShortTreeTask[];
}

export declare type PlantingDetailsResult = {
    planting_details: IPlantTreeInfo;
}

export declare type CuttingDetailsResult = {
    cutting_details: ICutTreeInfo;
}

export declare type City = {
    name: string;
    local_name: string;
    available: boolean;
    districts: Option[];

    center_point: Point;
    min_zoom_level: number;
    bounds: LatLngBounds;
    view: Viewport;
}

export class CitiesResult {
    cities: City[] = [];

    static getDistricts(city?: City) : Map<string, string> {
        return city ? new Map<string,string>(city.districts.map(_ => [_.value, _.label])):new Map<string, string>();
    }
}

export declare type Issue = {
    tree_uid: string;
    category: string;
    comment: string;
    author_name: string;
    author_surname: string;
    author_e164?: string;
    status?: string;
}

export declare type IssuesResult = {
    issues: Issue[];
}
