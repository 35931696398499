export const FILTER_DATA = "FILTER_DATA";
export const CLEAR_FILTERS = "CLEAR_FILTERS";
export const UPDATE_METADATA = "UPDATE_METADATA";
export const UPDATE_MAP_TREES = "UPDATE_MAP_TREES";
export const UPDATE_ISSUES_DATA = "UPDATE_ISSUES_DATA";
export const UPDATE_TREES_COUNT = "UPDATE_TREES_COUNT";
export const UPDATE_SELECTED_AREAS = "UPDATE_SELECTED_AREAS";
export const UPDATE_LINE_PATH = "UPDATE_LINE_PATH";
export const UPDATE_TREES_ALONG_PATH = "UPDATE_TREES_ALONG_PATH";
export const UPDATE_PROGRESS_STATE = "UPDATE_PROGRESS_STATE";
export const TOGGLE_FILTER_SIDEBAR = "TOGGLE_FILTER_SIDEBAR";
export const LIST_OF_CITIES_UPDATED = "LIST_OF_CITIES_UPDATED";

export const VIEWPORT_CHANGED = "VIEWPORT_CHANGED";

export const ADD_MARKERS = "ADD_MARKERS";
export const PEEK_MARKER = "PEEK_MARKER";
export const MOVE_MARKER = "MOVE_MARKER";
export const ATTACH_MARKER = "ATTACH_MARKER";
export const DETACH_MARKER = "DETACH_MARKER";
export const DELETE_MARKER = "DELETE_MARKER";
export const SAVE_MARKER_DATA = "SAVE_MARKER_DATA";

export const TOGGLE_LOGIN_DIALOG = "TOGGLE_LOGIN_DIALOG";
export const TOGGLE_MARKER_DETAILS = "TOGGLE_MARKER_DETAILS";
export const TOGGLE_INSPECT_DIALOG = "TOGGLE_INSPECT_DIALOG";
export const TOGGLE_ADD_TREE_DIALOG = "TOGGLE_ADD_TREE_DIALOG";
export const TOGGLE_TREE_HISTORY_DIALOG = "TOGGLE_TREE_HISTORY_DIALOG";
export const TOGGLE_CUT_TREE_DIALOG = "TOGGLE_CUT_TREE_DIALOG";
export const TOGGLE_TREETASK_DIALOG = "TOGGLE_TREETASK_DIALOG";
export const TOGGLE_PLANT_TREE_DIALOG = "TOGGLE_PLANT_TREE_DIALOG";
export const TOGGLE_REPORT_ISSUE_DIALOG = "TOGGLE_REPORT_ISSUE_DIALOG";
export const TOGGLE_REQUEST_FOR_ACTIONS = "TOGGLE_REQUEST_4_ACTIONS";

export const TOGGLE_ADD_MARKER_DIALOG = "TOGGLE_ADD_MARKER_DIALOG";

export const CITY_CHANGED = "CITY_CHANGED";
export const USER_LOGGED_IN = "USER_LOGGED_IN";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";
export const USER_TOKENS_REFRESHED = "USER_TOKENS_REFRESHED";
