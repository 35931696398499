import React, { useCallback, useReducer } from 'react';
import { Modal, Form, Button, InputGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router";
import { toast } from 'react-toastify';
import { loggedIn, toggleLoginDialog }   from '../../../redux/actions';
import { getLoginModalState } from '../../../redux/selectors';
import { Required } from '../required-field-componnent';
import { login } from '../../../services/authorization-service';
import { Logo } from '../svg-component';
import { PWD_CHANGED, RELOAD, USERID_CHANGED } from './action-type-constants';
import { modalValuesReducer } from './modal-values-reducer';

export const LoginModal: React.FC = () => {
    const open = useSelector(getLoginModalState);
    
    const history = useHistory();
    const dispatch = useDispatch();
    const [values, dispatchValues] = useReducer(modalValuesReducer, {});

    const close = useCallback(() => {
        dispatchValues({type: RELOAD, payload: {}});
        dispatch(toggleLoginDialog());
    }, [dispatch]);

    const onLogin = useCallback(async () => {
        if(!values.username || !values.password) {
            dispatchValues({
                type: RELOAD,
                payload: {
                    username: values.username,
                    password: values.password
                }
            });
            return;
        }

        const loginResult = await login(values.username, values.password);
        if(!loginResult.success) {
            toast.info((
                <div>
                    <h5 className="d-flex align-items-center">
                        <i className="fas fa-2x fa-shield-alt mr-3"></i>Невдала спроба авторизації.
                    </h5>
                    <hr/>
                    <p>{loginResult.message}</p>
                </div>
            ));
            return;
        }

        dispatch(loggedIn(loginResult.getValue()));
        close();

        history.push('/map');
    }, [values, history, dispatch, close]);

    const usernameChaned = useCallback((e) => dispatchValues({
        type: USERID_CHANGED,
        payload: e.target.value
    }), []);
    const passwordChanged = useCallback((e) => dispatchValues({
        type: PWD_CHANGED,
        payload: e.target.value
    }), []);
    
    return(
        <Modal show={open} centered onHide={close}>
            <Modal.Header className="bg-dark-gradient" closeButton={true}>
                <Modal.Title className="w-100 pl-4 text-center">
                    <div className="logo-container"><Logo size={98} /></div>
                </Modal.Title>
            </Modal.Header>
            
            <Modal.Body>
                <Form>
                    <Form.Group className="m-auto">
                        <Form.Label className="hide-on-sm">Електронна скринька</Form.Label>
                        <Required value={values.username}>
                            <Form.Control id="email" type="text"
                                placeholder="Введіть електронну скриньку"
                                autoComplete="username"
                                aria-autocomplete="list"
                                onChange={usernameChaned} />
                        </Required>
                        <Form.Text className="text-muted mb-2">
                            Ми не передаємо цю інформацію стороннім особам.
                        </Form.Text>
                    </Form.Group>
                    <Form.Group className="m-auto">
                        <Form.Label className="hide-on-sm">Пароль</Form.Label>
                        <Required value={values.password}>
                            <InputGroup>
                                <Form.Control id="pwd" type="password"
                                    placeholder="Введіть пароль"
                                    autoComplete="current-password"
                                    aria-autocomplete="list"
                                    onChange={passwordChanged} />
                                <InputGroup.Append>
                                    <Button variant="info" onClick={onLogin}>Увійти</Button>
                                </InputGroup.Append>
                            </InputGroup>
                        </Required>
                        <Form.Text className="text-muted">
                            Не розголошуйте ваш пароль нікому.
                        </Form.Text>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer className="bg-dark-gradient"></Modal.Footer>
        </Modal>);
};