import { AnyAction, Reducer } from 'redux';
import { ProgressInicator } from '../app-state';
import { UPDATE_PROGRESS_STATE } from '../constants';

const initialProgress = new ProgressInicator();
const progressReducer: Reducer<ProgressInicator, AnyAction> = (state:ProgressInicator = initialProgress, action:AnyAction) => {
    let result = {...state};

    switch(action.type) {
        case UPDATE_PROGRESS_STATE: {
            const { data } = action.payload;

            result.progress = data.progress;
            result.message = data.message;
            result.show = data.show;
        };
        break;

        default: result = state; break;
    }

    return result;
}

export default progressReducer;