import React from 'react';
import ReactDOM from 'react-dom';
import store from './redux/store'
import { Provider } from 'react-redux';
import { BrowserRouter } from "react-router-dom";

import './assets/css/all.min.css';
import './assets/css/fonts.min.css';
import './assets/css/bootstrap.min.css';

import { App } from './App.tsx';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <App className="App container-fluid vh-100 d-flex flex-column justify-content-between" />
        </BrowserRouter>
    </Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();