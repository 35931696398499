import { ActionCreator, Action } from "redux";
import { Viewport } from "react-leaflet";
import { LatLng } from "leaflet";
import { Feature, FeatureCollection, LineString, Point } from "geojson";
import { UserAuthority } from "./app-state";
import {
    City,
    ITree,
    IssuesResult,
    IUpdateTrees,
    ITreeMetadata,
    IFilterMapQuery,
    IProgressIndicator
} from "../domain/types";
import {
    UPDATE_METADATA,
    FILTER_DATA,
    CLEAR_FILTERS,
    VIEWPORT_CHANGED,
    DELETE_MARKER,
    ADD_MARKERS,
    MOVE_MARKER,
    TOGGLE_LOGIN_DIALOG,
    TOGGLE_MARKER_DETAILS,
    TOGGLE_ADD_TREE_DIALOG,
    TOGGLE_TREE_HISTORY_DIALOG,
    TOGGLE_INSPECT_DIALOG,
    TOGGLE_ADD_MARKER_DIALOG,
    TOGGLE_REQUEST_FOR_ACTIONS,
    PEEK_MARKER,
    SAVE_MARKER_DATA,
    UPDATE_MAP_TREES,
    UPDATE_TREES_COUNT,
    USER_LOGGED_IN,
    USER_LOGGED_OUT,
    TOGGLE_TREETASK_DIALOG,
    TOGGLE_PLANT_TREE_DIALOG,
    TOGGLE_CUT_TREE_DIALOG,
    TOGGLE_REPORT_ISSUE_DIALOG,
    CITY_CHANGED,
    UPDATE_ISSUES_DATA,
    LIST_OF_CITIES_UPDATED,
    TOGGLE_FILTER_SIDEBAR,
    ATTACH_MARKER,
    DETACH_MARKER,
    UPDATE_SELECTED_AREAS,
    UPDATE_PROGRESS_STATE,
    UPDATE_TREES_ALONG_PATH,
    UPDATE_LINE_PATH,
    USER_TOKENS_REFRESHED,
} from './constants';

interface AppAction<T> extends Action<string> {
    type: string;
    payload?: T;
}

export declare type LocalAction = {
    type: string;
    payload?: any;
};

export const toggleFiltersSidebar: ActionCreator<AppAction<any>> = () => ({type: TOGGLE_FILTER_SIDEBAR});

export const clearFilters: ActionCreator<AppAction<{requestTreesReload?: boolean}>> = (requestTreesReload) => ({type: CLEAR_FILTERS, payload: { requestTreesReload } });
export const applyFilters: ActionCreator<AppAction<{data: IFilterMapQuery}>> = (data: IFilterMapQuery) => ({type: FILTER_DATA, payload: { data }});
export const updateMetadata: ActionCreator<AppAction<{data: ITreeMetadata }>> = (data: ITreeMetadata) => ({type: UPDATE_METADATA, payload: { data }});
export const updateMapTrees: ActionCreator<AppAction<{data: IUpdateTrees}>> = (data:IUpdateTrees) => ({type: UPDATE_MAP_TREES, payload: { data }});
export const updateIssues: ActionCreator<AppAction<{data: IssuesResult}>> = (data) => ({type: UPDATE_ISSUES_DATA, payload: { data }});
export const updateTreesCount: ActionCreator<AppAction<{count?: number}>> = (count?: number) => ({type: UPDATE_TREES_COUNT, payload: { count }});
export const updateListOfCities: ActionCreator<AppAction<{data: City[]}>> = (data: City[]) => ({type: LIST_OF_CITIES_UPDATED, payload: { data } });
export const updateProgess: ActionCreator<AppAction<{data: IProgressIndicator}>> = (data) => ({type: UPDATE_PROGRESS_STATE, payload: { data }});
export const updateSelectedAreas: ActionCreator<AppAction<{data?: FeatureCollection, requestTreesReload?: boolean}>> = (data, requestTreesReload) => ({type: UPDATE_SELECTED_AREAS, payload: { data, requestTreesReload }});
export const updateLinePath: ActionCreator<AppAction<{data?: Feature<LineString>}>> = (data) => ({type: UPDATE_LINE_PATH, payload: { data }});
export const updateTreesAlongPath: ActionCreator<AppAction<{data?: FeatureCollection<Point>}>> = (data) => ({type: UPDATE_TREES_ALONG_PATH, payload: { data }});
export const viewportChanged: ActionCreator<AppAction<{ data: Viewport, requestTreesReload?: boolean }>> = (data, requestTreesReload) => ({type: VIEWPORT_CHANGED, payload: { data, requestTreesReload } });

export const addMarkers: ActionCreator<AppAction<{points: Feature<Point>[], requestClearAlongLineData?: boolean}>> = (points: Feature<Point>[], requestClearAlongLineData) => ({type: ADD_MARKERS, payload: { points, requestClearAlongLineData }});
export const peekMarker: ActionCreator<AppAction<{ id: string, bySearch?:boolean}>> = (id: string, bySearch:boolean = false ) => ({type: PEEK_MARKER, payload: { id, bySearch } });
export const attachMarker: ActionCreator<AppAction<{ id: string }>> = (id: string) => ({type: ATTACH_MARKER, payload: { id } });
export const detachMarker: ActionCreator<AppAction<{ id: string }>> = (id: string) => ({type: DETACH_MARKER, payload: { id } });
export const deleteMarkers: ActionCreator<AppAction<{ ids: string[] }>> = (ids: string[]) => ({type: DELETE_MARKER, payload: { ids } });
export const savedMarker: ActionCreator<AppAction<{ id: string, data: Feature<Point, ITree> }>> = (id: string, data: Feature<Point, ITree>) => ({type: SAVE_MARKER_DATA, payload: { id, data } });
export const moveMarkerTo: ActionCreator<AppAction<{ id: string, point: LatLng }>> = (id: string, point: LatLng) => ({type: MOVE_MARKER, payload: { id, point } });

export const toggleLoginDialog: ActionCreator<AppAction<any>> = () => ({type: TOGGLE_LOGIN_DIALOG});
export const toggleAddMarkerDialog: ActionCreator<AppAction<any>> = () => ({type: TOGGLE_ADD_MARKER_DIALOG});
export const toggleMarkerDetailsDialog: ActionCreator<AppAction<any>> = () => ({type: TOGGLE_MARKER_DETAILS});
export const toggleAddTreeDialog: ActionCreator<AppAction<any>> = () => ({type: TOGGLE_ADD_TREE_DIALOG});
export const toggleTreeHistoryDialog: ActionCreator<AppAction<any>> = (show:boolean) => ({type: TOGGLE_TREE_HISTORY_DIALOG, show});
export const toggleInspectDialog: ActionCreator<AppAction<any>> = () => ({type: TOGGLE_INSPECT_DIALOG});
export const toggleTreeTaskDialog: ActionCreator<AppAction<any>> = () => ({type: TOGGLE_TREETASK_DIALOG});
export const toggleCutTreeDialog:  ActionCreator<AppAction<any>> = () => ({type: TOGGLE_CUT_TREE_DIALOG});
export const togglePlantTreeDialog: ActionCreator<AppAction<any>> = () => ({type: TOGGLE_PLANT_TREE_DIALOG});
export const toggleReportIssueDialog: ActionCreator<AppAction<any>> = () => ({type: TOGGLE_REPORT_ISSUE_DIALOG});
export const toggleRequestForActionsDialog: ActionCreator<AppAction<any>> = () => ({type: TOGGLE_REQUEST_FOR_ACTIONS});
export const loggedIn: ActionCreator<AppAction<{ data: UserAuthority }>> = (data: UserAuthority) => ({type: USER_LOGGED_IN, payload: { data } });
export const loggedOut: ActionCreator<AppAction<any>> = () => ({ type: USER_LOGGED_OUT });
export const tokenRefreshed: ActionCreator<AppAction<{accessToken: string, refreshToken: string}>> = (accessToken, refreshToken) => ({type: USER_TOKENS_REFRESHED, payload: {accessToken, refreshToken}});
export const cityChanged: ActionCreator<AppAction<{city: string}>> = (city: string) => ({ type: CITY_CHANGED, payload: {city: city} });
