import React, { useState, useRef, useCallback } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import lodash from 'lodash';

import { searchTreeById } from "../../services/tree-api-service";
import { metadataSelector,currentCitySelector, authoritySelector } from "../../redux/selectors";
import { useDispatch, useSelector } from "react-redux";
import { Feature, Point } from "geojson";
import { ITree } from "../../domain/types";
import {
    peekMarker,
    clearFilters,
    viewportChanged
} from "../../redux/actions";
import { CoordinatesHelpers } from "../../domain/helpers";
import { MapData } from "../../redux/app-state";

import "react-bootstrap-typeahead/css/Typeahead.css";


const Search: React.FC = () => {
    const user = useSelector(authoritySelector);
    const city = useSelector(currentCitySelector);
    const metadata = useSelector(metadataSelector);
    const selfRef = useRef<AsyncTypeahead<any>>(null);
    
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState<Feature<Point, ITree>[]>([]);

    const dispatch = useDispatch();
    const getLabelKey = useCallback((item: Feature<Point, ITree>) => {
        const { identifier, genus } = item.properties;
        const genusLabel = metadata.genus.get(genus);
        return `${identifier} (${genusLabel})`;
    }, [metadata]);

    
    const handleSearch = async (query: string) => {
        setIsLoading(true);
        const data = await searchTreeById(user, query, city);
        const { features } = data.getValue();
        setOptions(features);
        setIsLoading(false);
    };
    
    const handleSelect = useCallback((selections: Feature<Point, ITree>[]) => {
        if (!selections.length) {
            return;
        }
        const [feature] = selections;
        const [lng, lat] = feature.geometry.coordinates;
        const flyTo = CoordinatesHelpers.toViewport(MapData.maxZoom - 2, lat, lng);

        dispatch(clearFilters());
        dispatch(viewportChanged(flyTo, true));
        dispatch(peekMarker(feature.id, true));

        lodash.delay(() => {
            const searchInput = selfRef.current;
            if (searchInput) {
                //@ts-ignore
                searchInput.clear();
                //@ts-ignore
                searchInput.blur();
            }
        }, 2000);

    }, [dispatch, selfRef]);

    return (
        <div className="navbar-search p-2">
            <AsyncTypeahead
                id="tree-search"
                ref={selfRef}
                isLoading={isLoading}
                useCache={false}
                minLength={1}
                delay={800}
                placeholder="Пошук по номеру дерева"
                emptyLabel="Нічого не знайдено"
                promptText="Пошук..."
                searchText="Пошук..."
                labelKey={getLabelKey}
                onSearch={handleSearch}
                onChange={handleSelect}
                options={options}
            />
        </div>
    );
};

export default Search;
