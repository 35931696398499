import React from 'react';

interface Props {
    size: number;
    id?: string;
    fill?: string;
    higlighted?: boolean;
    className?: string;
    onClick?: (e:any) => void;
    onAnimationEnd?: React.AnimationEventHandler<any>;
}

export const Logo: React.FC<Props> = ({ size }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" height={size} width={size}>
            <title>inspectree-logo</title>
            <path d="M24,35.82A16.08,16.08,0,1,1,40.08,19.74,16.09,16.09,0,0,1,24,35.82Z" fill="#34c100"/>
            <path d="M24,5.16A14.58,14.58,0,1,1,9.42,19.74,14.58,14.58,0,0,1,24,5.16m0-3A17.58,17.58,0,1,0,41.58,19.74,17.59,17.59,0,0,0,24,2.16Z"/>
            <path d="M24,5.16h0V34.32h0A14.58,14.58,0,0,0,24,5.16Z" fill="green"/>
            <path d="M24.65,8.74,26,43.83A2,2,0,0,1,22,44v-.14L23.35,8.74A.66.66,0,0,1,24,8.11.67.67,0,0,1,24.65,8.74Z"/>
            <path d="M24,2.16a17.58,17.58,0,0,1,1.77,35.07h0L26,43.75a2,2,0,0,1-1.27,2,1.9,1.9,0,0,1-.69.12,2,2,0,0,1-2-1.9v-.11l.19-6.6h0a17.58,17.58,0,0,1,.81-35c.32,0,.64,0,1,0m0-2-1.06,0A19.59,19.59,0,0,0,20.18,39L20,43.77V44A4,4,0,1,0,28,43.69L27.82,39A19.58,19.58,0,0,0,24,.16Z" fill="#fff"/>
            <path d="M22.7,23.1a11.07,11.07,0,0,1,1.17-3.59,13.27,13.27,0,0,1,2.07-3,14.26,14.26,0,0,1,2.71-2.27,15.22,15.22,0,0,1,1.51-.88c.51-.26,1.05-.47,1.57-.71a.58.58,0,0,1,.54,1l-.06,0c-.46.27-.94.53-1.38.81a15.31,15.31,0,0,0-1.29.95,12.77,12.77,0,0,0-2.17,2.24,11.08,11.08,0,0,0-1.47,2.67,10.44,10.44,0,0,0-.45,1.44,7.39,7.39,0,0,0-.18,1.36v.05a1.3,1.3,0,0,1-2.59,0A1.1,1.1,0,0,1,22.7,23.1Z"/>
            <path d="M22.58,34.39A11.73,11.73,0,0,1,23,31.9a14.41,14.41,0,0,1,.84-2.27,12.45,12.45,0,0,1,6.84-6.37,15.79,15.79,0,0,1,2.22-.68c.74-.16,1.5-.24,2.25-.36a.58.58,0,0,1,.66.49.56.56,0,0,1-.41.64l-.09,0c-.68.18-1.38.33-2,.54a13.68,13.68,0,0,0-1.92.82,10.61,10.61,0,0,0-3.19,2.46,10,10,0,0,0-2,3.41,11.64,11.64,0,0,0-.49,1.91,9,9,0,0,0-.15,1.87v.06a1.51,1.51,0,0,1-3,.1A.86.86,0,0,1,22.58,34.39Z"/>
            <path d="M14.18,15.37a13.47,13.47,0,0,1,4.3,1.86,13.21,13.21,0,0,1,3.42,3.29,17.91,17.91,0,0,1,2.26,4.17,19.84,19.84,0,0,1,1.2,4.65,1.37,1.37,0,1,1-2.72.34s0-.08,0-.12V29.5a17.8,17.8,0,0,0-.69-4.06,15.67,15.67,0,0,0-1.65-3.83,11.77,11.77,0,0,0-2.72-3.12,12.37,12.37,0,0,0-3.7-2l-.06,0a.58.58,0,0,1-.33-.75A.59.59,0,0,1,14.18,15.37Z"/>
        </svg>
    );
}

export const PlantICON: React.FC = () => {
    return (
        <svg width='24' height="24" viewBox="0 0 26 26" className="mb-1">
            <path fill="currentColor" d="M 25.40586,4.8942954 21.105706,0.59414082 c -0.791683,-0.79167975 -2.080508,-0.79167975 -2.872187,0 L 16.574495,2.2531637 c -0.961796,0.9617964 -1.485352,2.2501163 -1.485352,3.5826157 0,0.3316015 0.0325,0.6667576 0.09903,0.9998825 0.08836,0.4412889 0.241211,0.8551558 0.433163,1.2456636 L 10.022193,13.679956 7.7238345,11.381597 c -0.6347654,-0.634765 -1.6635931,-0.634765 -2.2978507,0 L 1.9789541,14.828628 C -0.55959975,17.367182 -0.31940453,24.021047 0.82977473,25.170224 1.9789541,26.319405 8.6328182,26.559599 11.171372,24.021047 l 3.447031,-3.447031 c 0.634765,-0.634767 0.634765,-1.663594 0,-2.29785 l -2.298359,-2.298359 5.599138,-5.599139 c 0.391016,0.192462 0.805899,0.345312 1.247695,0.433672 1.666135,0.333125 3.378475,-0.184843 4.579961,-1.3863272 L 25.40586,7.7669896 c 0.792187,-0.7921872 0.792187,-2.080507 0,-2.8726942 z m -3.956873,2.2338664 a 1.8215228,1.8215228 0 0 1 -1.646329,0.497656 C 19.080042,7.4805835 18.519417,6.9194509 18.374182,6.1953106 18.255862,5.5945687 18.440709,4.9811313 18.871838,4.5510143 l 0.797265,-0.7972654 2.577149,2.5771475 z" />
        </svg>
    );
}

export const CutICON: React.FC = () => {
    return (
        <svg width="28" height="22" viewBox="0 0 8.4666665 6.8791669" className="mb-1">
            <path transform="translate(0,-290.12082)" fill="currentColor" d="m 0.06201212,296.02498 c -0.08268283,0.084 -0.08268283,0.22008 0,0.30405 l 0.59875433,0.60797 c 0.0826828,0.084 0.2166948,0.084 0.29937712,0 L 4.0805195,293.76787 3.18252,292.85584 Z m 6.89308388,-3.75441 -0.7002222,-0.71116 0.4564079,-0.46355 c 0.082683,-0.084 0.082683,-0.22008 0,-0.30405 l -0.5986222,-0.60798 c -0.082682,-0.084 -0.2166946,-0.084 -0.2993771,0 l -0.4564081,0.46354 -0.3943628,-0.40066 c -0.082683,-0.084 -0.19103,-0.12589 -0.2993771,-0.12589 -0.1083474,0 -0.2166948,0.0419 -0.2993771,0.12589 l -1.276487,1.29644 c -0.1653657,0.16795 -0.1653657,0.44016 0,0.60797 l 1.9927165,2.02386 v 1.53519 h 0.4233354 c 1.6365863,0 2.9633445,-1.34749 2.9633445,-3.00965 v -0.42995 z m -1.0284394,2.53657 v -0.50976 c 0.5196443,-0.21833 0.9339834,-0.63902 1.1488256,-1.16677 h 0.5019164 c -0.1686729,0.84095 -0.822593,1.50509 -1.650742,1.67653 z" />
        </svg>
    );
}

export const BarcodeICON: React.FC<{size:number, higlighted?: boolean}> = (props) => {
    const fillColor = props.higlighted === undefined || props.higlighted === true ? "#000" : "#fff";
    return (
        <svg width={props.size} height={props.size} viewBox="0 0 32 32">
            <rect width="1" height="12" fill={fillColor} />
            <rect x="9.66" width="1" height="12" fill={fillColor} />
            <rect x="12.68" width="1" height="12" fill={fillColor} />
            <rect x="15.7" width="1" height="12" fill={fillColor} />
            <rect x="4.1" width="2" height="12" fill={fillColor} />
            <rect x="20.33" width="1" height="12" fill={fillColor} />
            <rect x="23.36" width="2.01" height="12" fill={fillColor} />
            <rect x="28.99" width="3.01" height="12" fill={fillColor} />

            <rect y="14.5" width="32" height="3" fill="#e52421"/>

            <rect y="20" width="1" height="12" fill={fillColor} />
            <rect x="9.66" y="20" width="1" height="12" fill={fillColor} />
            <rect x="12.68" y="20" width="1" height="12" fill={fillColor} />
            <rect x="15.7" y="20" width="1" height="12" fill={fillColor} />
            <rect x="4.1" y="20" width="2" height="12" fill={fillColor} />
            <rect x="20.33" y="20" width="1" height="12" fill={fillColor} />
            <rect x="23.36" y="20" width="2.01" height="12" fill={fillColor} />
            <rect x="28.99" y="20" width="3.01" height="12" fill={fillColor} />
        </svg>
    );
}

export const AddItem: React.FC<Props> = ({ size, id, className, onClick }) => {
    return (
        <svg height={size} width={size*0.75} viewBox="0 0 384 512" className={`add-item ${className || ''}`}>
            <g>
                <path data-idx={id} onClick={onClick} fill="currentColor" d="M377,105L279.1,7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1C384,115.6,381.5,109.5,377,105z"/>
                <path data-idx={id} onClick={onClick} fill="currentColor" d="M224,136V0H24C10.7,0,0,10.7,0,24v464c0,13.3,10.7,24,24,24h336c13.3,0,24-10.7,24-24V160H248
                    C234.8,160,224,149.2,224,136z"/>
                <path data-idx={id} onClick={onClick} fill="#2E2E2E" d="M288,296v48c0,4.4-3.6,8-8,8h-56v56c0,4.4-3.6,8-8,8h-48c-4.4,0-8-3.6-8-8v-56h-56c-4.4,0-8-3.6-8-8v-48
                    c0-4.4,3.6-8,8-8h56v-56c0-4.4,3.6-8,8-8h48c4.4,0,8,3.6,8,8v56h56C284.4,288,288,291.6,288,296z"/>
            </g>
        </svg>
    );
};

export const Item: React.FC<Props> = ({ size, id, className, onClick }) => {
    return (
        <svg height={size} width={size*0.75} viewBox="0 0 384 512" className={`item-icon ${className || ''}`}>
            <g>
                <g>
                    <path data-idx={id} onClick={onClick} fill="currentColor" d="M224,136V0H24C10.7,0,0,10.7,0,24v464c0,13.3,10.7,24,24,24h336c13.3,0,24-10.7,24-24V160H248
                        C234.8,160,224,149.2,224,136z"/>
                    <path data-idx={id} onClick={onClick} fill="currentColor" d="M377,105L279.1,7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1C384,115.6,381.5,109.5,377,105z"/>
                </g>
                <g>
                    <path data-idx={id} onClick={onClick} fill="#2E2E2E" d="M276,352H108c-6.6,0-12,5.4-12,12v8c0,6.6,5.4,12,12,12h168c6.6,0,12-5.4,12-12v-8
                        C288,357.4,282.6,352,276,352z"/>
                    <path data-idx={id} onClick={onClick} fill="#2E2E2E" d="M276,288H108c-6.6,0-12,5.4-12,12v8c0,6.6,5.4,12,12,12h168c6.6,0,12-5.4,12-12v-8
                        C288,293.4,282.6,288,276,288z"/>
                    <path data-idx={id} onClick={onClick} fill="#2E2E2E" d="M276,224H108c-6.6,0-12,5.4-12,12v8c0,6.6,5.4,12,12,12h168c6.6,0,12-5.4,12-12v-8
                        C288,229.4,282.6,224,276,224z"/>
                </g>
            </g>
        </svg>
    );
}

export const RemoveItem: React.FC<Props> = ({ size, id, className, onClick }) => {
    return (
        <svg height={size} width={size*0.75} viewBox="0 0 384 512" className={`trash-item ${className || ''}`}>
            <g>
                <g>
                    <path data-idx={id} onClick={onClick} fill="currentColor" d="M224,136V0H24C10.7,0,0,10.7,0,24v464c0,13.3,10.7,24,24,24h336c13.3,0,24-10.7,24-24V160H248
                        C234.8,160,224,149.2,224,136z"/>
                    <path data-idx={id} onClick={onClick} fill="currentColor" d="M377,105L279.1,7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1C384,115.6,381.5,109.5,377,105z"/>
                </g>
                <g>
                    <path data-idx={id} onClick={onClick} fill="#2E2E2E" d="M127.4,397.3c0.6,9.5,8.5,17,18.1,17h93c9.6,0,17.5-7.4,18.1-17l8.1-128.3H119.4L127.4,397.3z"/>
                    <path data-idx={id} onClick={onClick} fill="#2E2E2E" d="M270.7,232.7h-45.4l-3.6-7.1c-1.5-3.1-4.7-5-8.1-5h-43.2c-3.4,0-6.6,1.9-8.1,5l-3.6,7.1h-45.4
                        c-3.4,0-6.1,2.8-6.1,6.1v12.1c0,3.4,2.8,6.1,6.1,6.1h157.3c3.4,0,6.1-2.8,6.1-6.1v-12.1C276.7,235.4,274,232.7,270.7,232.7z"/>
                </g>
            </g>
        </svg>
    );
};

export const ApplyPrevSavedIcon: React.FC<Props> = ({ size, className, onClick, onAnimationEnd }) => {
    return (
        <svg height={size} width={size*0.75} viewBox="0 0 384 512" className={className || ''} onAnimationEnd={onAnimationEnd}>
            <path onClick={onClick} fill="currentColor" d="M224,136V0H24A23.94,23.94,0,0,0,0,24V488a23.94,23.94,0,0,0,24,24H360a23.94,23.94,0,0,0,24-24V160H248A24.07,24.07,0,0,1,224,136Z"/>
            <path onClick={onClick} fill="currentColor" d="M377,105,279.1,7a24,24,0,0,0-17-7H256V128H384v-6.1A23.92,23.92,0,0,0,377,105Z"/>
            <path onClick={onClick} fill="#2E2E2E" d="M289.18,352H224v80a16,16,0,0,1-16,16H176a16,16,0,0,1-16-16V352H94.82c-14.28,0-21.41-17.29-11.27-27.36L180,229a17.05,17.05,0,0,1,24,0l96.42,95.7C310.58,334.72,303.46,352,289.18,352Z"/>
        </svg>
    );
};

export const DetatchTreeIcon: React.FC<Props> = ({ size, fill }) => {
    return (
        <svg height={size} width={size} viewBox="0 0 512 512">
            <path fill={fill} d="M378.3,378.5L298.4,288h30.7c8.9,0.1,17-5,20.8-13.1c3.8-7.9,2.5-17.4-3.3-24.1L268.4,160h28.9c9,0,17.2-5.3,20.9-13.6
                c3.5-8.2,1.9-17.6-4.2-24.1L203.7,4.8c-6-6.4-17.3-6.4-23.3,0L70.1,122.3c-6.1,6.5-7.8,15.9-4.2,24.1c3.6,8.3,11.8,13.6,20.8,13.6
                h28.9l-78.1,90.9c-5.8,6.6-7.1,16.1-3.3,24C38,283,46.1,288.1,55,288h30.6L5.7,378.5c-6,6.6-7.4,16.2-3.6,24.3
                c3.8,8.1,12,13.3,21,13.3H160v24.5l-30.3,48.4c-4,7.9-0.8,17.5,7.1,21.5c2.2,1.1,4.7,1.7,7.2,1.7h96c8.8,0,16-7.2,16-16
                c0-2.5-0.6-4.9-1.7-7.2L224,440.4V416h136.9c9,0,17.2-5.1,21-13.3C385.7,394.7,384.3,385.1,378.3,378.5z"/>
            <path fill={fill} d="M506.8,198.1L444.7,127c-0.3-0.3-0.7-0.7-1-1c-7.8-7.2-19.9-6.8-27.1,1l-69,71.1c-11.4,12.3-2.7,32.3,14.1,32.3h37V448
                H268.2c-5.1,0-9.3,4.2-9.3,9.3c0,1.7,0.5,3.4,1.3,4.8l27.6,45.4c1.7,2.8,4.7,4.5,7.9,4.5h94.5h20.3h32.9c10.6,0,19.2-8.6,19.2-19.2
                V230.4h30.2C509.5,230.4,518.3,210.4,506.8,198.1z"/>
        </svg>
    );
};

export const AttachTreeIcon: React.FC<Props> = ({ size, fill }) => {
    return (
        <svg height={size} width={size} viewBox="0 0 512 512">
            <path fill={fill} d="M492.8,281.6h-30.2V19.2C462.6,8.6,454,0,443.4,0c0,0,0,0,0,0H253.9c-5.3,0-9.6,4.3-9.6,9.6c0,2.5,1,5,2.8,6.8l44.8,44.8
                c1.8,1.8,4.2,2.8,6.8,2.8h99.9v217.6h-37c-16.8,0-25.4,20-14.1,32.3l69,71.1c7.2,7.8,19.4,8.2,27.1,1c0.3-0.3,0.7-0.7,1-1l62.1-71.1
                C518.3,301.6,509.5,281.6,492.8,281.6z"/>
            <path fill={fill} d="M378.3,378.5L298.4,288H329c8.9,0.1,17-5,20.8-13.1c3.8-7.9,2.5-17.4-3.3-24.1L268.4,160h28.9c9.1,0.1,17.3-5.3,20.9-13.6
                c3.5-8.2,1.9-17.6-4.2-24.1L203.6,4.8c-6-6.4-17.3-6.4-23.3,0L70,122.3c-6.1,6.5-7.8,15.9-4.2,24.1c3.6,8.3,11.8,13.6,20.9,13.6
                h28.9l-78.1,90.9c-5.8,6.7-7.1,16.1-3.2,24.1c3.8,8,11.9,13.1,20.8,13h30.6L5.7,378.5c-6,6.6-7.4,16.2-3.6,24.3
                c3.8,8.1,12,13.3,21,13.3H160v24.5l-30.3,48.4c-4,7.9-0.8,17.5,7.1,21.5c2.2,1.1,4.7,1.7,7.2,1.7h96c8.8,0,16-7.2,16-16
                c0-2.5-0.6-4.9-1.7-7.2L224,440.4V416h136.8c9,0,17.2-5.1,21-13.3C385.7,394.7,384.3,385.1,378.3,378.5z"/>
        </svg>
    );
};