import { PWD_CHANGED, RELOAD, USERID_CHANGED } from "./action-type-constants";
import { LocalAction } from "../../../redux/actions";

declare type ModalValues = {
    username?: string;
    password?: string;
};

export function modalValuesReducer(state: ModalValues, action: LocalAction) {
    const { type, payload } = action;
    const result = { ...state };

    switch(type) {
        case USERID_CHANGED:
            result.username = payload;
            break;
        case PWD_CHANGED:
            result.password = payload;
            break;
        case RELOAD:
            result.username = payload.username;
            result.password = payload.password;
            break;
    }
    
    return result;
};