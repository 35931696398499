import { AnyAction, Reducer } from 'redux';
import { DrawingElements } from '../app-state';
import { UPDATE_SELECTED_AREAS, UPDATE_TREES_ALONG_PATH, CLEAR_FILTERS, CITY_CHANGED, USER_LOGGED_OUT, UPDATE_LINE_PATH, ADD_MARKERS } from '../constants';

const initialState = new DrawingElements();
const drawingReducer: Reducer<DrawingElements, AnyAction> = (state:DrawingElements = initialState, action:AnyAction) => {
    let result = { ...state };

    switch(action.type) {
        case UPDATE_SELECTED_AREAS: {
            const { data } = action.payload;
            result.selectedAreas = data;
        };
        break;
        case ADD_MARKERS: {
            const { requestClearAlongLineData } = action.payload;
            if (requestClearAlongLineData) {
                result.treesAlongPath = undefined;
                result.path = undefined;
            }
        };
        break;
        case UPDATE_TREES_ALONG_PATH: {
            const { data } = action.payload;
            result.treesAlongPath = data;
        };
        break;
        case UPDATE_LINE_PATH: {
            const { data } = action.payload;
            result.path = data;
        };
        break;

        case CITY_CHANGED:
        case CLEAR_FILTERS:
        case USER_LOGGED_OUT:
            result = new DrawingElements();
            break;

        default: result = state; break;
    }

    return result;
};

export default drawingReducer;