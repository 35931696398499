import modal from './modals-reducer';
import sidebar from './sidebar-reducer';
import metadata from './metadata-reducer';
import filters from './filter-data-reducer';
import mapData from './map-reducer';
import authority from './authority-reducer';
import cities from './cities-data-reducer';
import drawing from './drawing-reducer';
import progress from './progress-reducer';
import { combineReducers } from 'redux';

export default combineReducers({
    modal,
    cities,
    drawing,
    sidebar,
    mapData,
    filters,
    metadata,
    authority,
    progress
});
