import { Reducer, AnyAction } from "redux";
import {
    VIEWPORT_CHANGED,
    ADD_MARKERS,
    PEEK_MARKER,
    DELETE_MARKER,
    MOVE_MARKER,
    UPDATE_MAP_TREES,
    SAVE_MARKER_DATA,
    UPDATE_ISSUES_DATA,
    UPDATE_TREES_COUNT,
    ATTACH_MARKER,
    DETACH_MARKER,
    CITY_CHANGED,
    USER_LOGGED_IN,
    USER_LOGGED_OUT,
    FILTER_DATA,
    CLEAR_FILTERS,
    UPDATE_SELECTED_AREAS
} from "../constants";
import { MapData } from "../app-state";
import { UID } from "../../domain/types";
import { CoordinatesHelpers } from "../../domain/helpers";
import { Feature, Point } from 'geojson';
const initialState = new MapData();

const mapDataReducer: Reducer<MapData, AnyAction> = (state = initialState, action:AnyAction) => {
    let result = { ...state };

    switch(action.type) {

        case VIEWPORT_CHANGED: {
            const { data, requestTreesReload } = action.payload;
            const { zoom } = result.viewport;
            const normalizedZoom = Math.round(Number(data.zoom || zoom));

            result.viewport = { zoom: normalizedZoom, center: data.center };
            result.requestTreesReload = Boolean(requestTreesReload);
        };
        break;

        case PEEK_MARKER:
            result.selectedID = action.payload.id;
            result.isSelectedFromSearch = action.payload.bySearch;
            break;

        case ADD_MARKERS: {
            let newMarkers: Array<Feature<Point>> = action.payload.points;
                newMarkers.forEach((m,idx) => m.id = `${UID.create(idx)}`);

            result.tempMarkers = [...state.tempMarkers, ...newMarkers];
        };
        break;

        case MOVE_MARKER: {
            const { id, point } = action.payload;
            if(UID.isTemporary(id)) {
                let markers = [...state.tempMarkers];
                    markers[markers.findIndex(_ => String(_.id) === id)].geometry.coordinates = CoordinatesHelpers.toPosition(point);

                result.tempMarkers = markers;
            } else {
                let trees = [...state.features];
                    trees[trees.findIndex(_ => String(_.id) === id)].geometry.coordinates = CoordinatesHelpers.toPosition(point);

                result.features = trees
            }
        };
        break;

        case SAVE_MARKER_DATA: {
            const { id, data } = action.payload;

            result.tempMarkers = [...result.tempMarkers.filter(_ => String(_.id) !== String(id))];
            result.features = [...result.features.filter(_ => String(_.id) !== id), data];
            result.preSaved = data;
        } break;

        case UPDATE_MAP_TREES: {
            const { data } = action.payload;
            result.requestTreesReload = false;

            result.clusters = (data.clusters || result.clusters);
            result.features = (data.features || result.features);
        }; break;

        case CITY_CHANGED:
        case USER_LOGGED_IN:
        case USER_LOGGED_OUT:
            result = new MapData();
            break;

        case FILTER_DATA:
        case CLEAR_FILTERS:
            result.requestTreesReload = (action.type === FILTER_DATA) || Boolean(action.payload.requestTreesReload);
            result.treesCount = undefined;
            result.features = [];
            break;

        case UPDATE_SELECTED_AREAS:
            result.treesCount = undefined;
            result.requestTreesReload = Boolean(action.payload.requestTreesReload);
            result.features = [...result.features];
            break;

        case UPDATE_ISSUES_DATA: {
            const { data } = action.payload;
            result.issues = [...data.issues];
        }; break;

        case DELETE_MARKER: {
            const { ids } = action.payload;
            const tempIDs = (ids as Array<string>).filter(id => UID.isTemporary(id));
            const featureIDs = (ids as Array<string>).filter(id => !UID.isTemporary(id));

            result.tempMarkers = tempIDs.length
                ? [...state.tempMarkers].filter(_ => !tempIDs.some(id => id === _.id))
                : result.tempMarkers;
            result.features = featureIDs.length
                ? [...state.features].filter(_ => !featureIDs.some(id => id === _.id))
                : result.features;
        };
        break;

        case ATTACH_MARKER: {
            const { id } = action.payload;
            result.detachedIDs = [...state.detachedIDs].filter(_ => String(_) !== id);
        };
        break;

        case DETACH_MARKER: {
            const { id } = action.payload;
            result.detachedIDs = [...state.detachedIDs, String(id)];
        };
        break;

        case UPDATE_TREES_COUNT: {
            const { count } = action.payload;
            result.treesCount = count;
        };
        break;

        default: result = state; break;
    }

    return result;
}

export default mapDataReducer;
