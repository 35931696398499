import { Viewport } from 'react-leaflet';
import { Feature, FeatureCollection, Polygon, LineString, Point } from 'geojson';
import {
    ITree,
    Issue,
    ICluster,
    ITreeTask,
    ITreeMetadata,
    IFilterMapQuery,
    HealthState,
    City
} from '../domain/types';


export class ModalsState {
    showLogin: boolean = false;
    showAddPoint: boolean = false;
    showTreeDetails: boolean = false;
    showInspectTree: boolean = false;
    showAddTree: boolean = false;
    showTreeHistory: boolean = false;
    showTreeTask: boolean = false;
    showPlanTree: boolean = false;
    showCutTree: boolean = false;
    showReportIssue: boolean = false;
    showRequestForActions: boolean = false;
}

export class SidebarsState {
    showFilters: boolean = false;
}

export class TreeMetadata implements ITreeMetadata {
    genus: Map<string,string> = new Map<string,string>();
    species: Map<string,string> = new Map<string,string>();
    selection: Map<string,string[]> = new Map<string,string[]>();

    owners: string[] = [];
    districts: Map<string,string> = new Map<string,string>();
}

export class Filters implements IFilterMapQuery  {
    genus: string[] = [];
    species: string[] = [];
    selection: string[] = [];
    district: string[] = [];
    health_state: string[] = [];
    needed_actions: string[] = [];
    max_age: string = '';
    min_age: string = '';
    isCut?: boolean;
}

export class MapData {
    static maxZoom: number = 21;
    static minZoom: number = 12;
    static nonClusteredZoomLevel = MapData.minZoom + 5;

    selectedID: string | null = null;
    isSelectedFromSearch: boolean = false;
    
    treesCount?: number;
    detachedIDs: string[] = [];
    clusters: ICluster[] = [];
    tempMarkers: Feature<Point>[] = [];
    features: Feature<Point, ITree>[] = [];
    requestTreesReload: boolean = true;

    viewport: Viewport = { zoom: MapData.minZoom, center: [0,0] };

    issues: Issue[] = [];
    tasks: ITreeTask[] = [];
    history: ITree[] = [];
    healthStates: HealthState[] = [];

    preSaved?: Feature<Point, ITree>;
}

export class UserAuthority {
    uid: string = "";
    name: string = "";
    email: string = "";
    city: string = "";
    
    accessToken: string = "";
    refreshToken: string = "";
    actions: string[] = [];
}

export class DrawingElements {
    selectedAreas?: FeatureCollection<Polygon>;
    treesAlongPath?: FeatureCollection<Point>;
    path?: Feature<LineString>;
}

export class ProgressInicator {
    show: boolean = false;
    message?: string;
    progress: number = 0;
}

export class ApplicationState {
    sidebar: SidebarsState = new SidebarsState();
    modal: ModalsState = new ModalsState();
    metadata: TreeMetadata = new TreeMetadata();
    filters: Filters = new Filters();
    mapData: MapData = new MapData();
    authority: UserAuthority = new UserAuthority();
    cities: City[] = [];

    drawing: DrawingElements = new DrawingElements();
    progress: ProgressInicator = new ProgressInicator();
}
