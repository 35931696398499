import React, { useRef, useEffect } from "react";

declare type Props = {
    value: any,
    message?: string,
    className?: string,
    errorClassName?: string,
    skipValidation?: boolean;
    onValidate?: (value:any) => boolean;
};

const validate = (value: any, onValidate?: (value:any) => boolean) => {
    return onValidate ? onValidate(value) : Boolean(value);
};

export const Required: React.FC<Props> = ({
    value,
    message,
    className,
    errorClassName,
    skipValidation,
    children,
    onValidate
}) => {
    const isInitialRun = useRef(true);
    const isValid = isInitialRun.current || validate(value, onValidate);
    const errorClass = 'h-100 ' + (skipValidation || isValid ? `${errorClassName || ''}` :  `required-field-err ${errorClassName || ''}`);

    useEffect(() => {
        if (isInitialRun.current) {
            isInitialRun.current = false;
        }
    }, []);

    return (
        <div className={className}>
            <div className={`${errorClass}`}>
                {!skipValidation && !isValid && message && (<div className="validation-tooltip">{message}</div>)}
                {children}
            </div>
        </div>
    );
}