import { Reducer, AnyAction } from "redux";
import { City } from "../../domain/types";
import { LIST_OF_CITIES_UPDATED } from "../constants";

const citiesReducer: Reducer<City[], AnyAction> = (state = [], action: AnyAction) => {
    let result = [...state];
    
    switch(action.type) {
        case LIST_OF_CITIES_UPDATED:
            result = [...action.payload.data];
            break;
            
        default: result = state; break;
    }

    return result;
}

export default citiesReducer;