import { AnyAction, Reducer } from 'redux';
import { SidebarsState } from '../app-state';
import { TOGGLE_FILTER_SIDEBAR } from '../constants';

const initialState = new SidebarsState();

const sidebarReducer: Reducer<SidebarsState, AnyAction> = (state:SidebarsState = initialState, action:AnyAction) => {
    let result = { ...state };

    switch(action.type) {
        case TOGGLE_FILTER_SIDEBAR:
            result.showFilters = !state.showFilters;
            break;

        default: return state;
    }

    return result;
};

export default sidebarReducer;
