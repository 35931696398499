import appConfig from "../app-config";
import { RequestMethod } from "../domain/types";
import { getResultFrom } from "./api-utilities";
import { tokenRefreshed } from '../redux/actions';
import { UserAuthority } from "../redux/app-state";

declare type RefreshTokenResult = {
    refresh_token: string;
    access_token: string;
};

declare type AuthTokens = {
    accessToken: string;
    refreshToken: string;
};

declare type ApiRequest = {
    url: string;
    method: RequestMethod;
    data?: any;
    headers?: string[][];
    auth?: AuthTokens;
};

export type DownloadResult = {
    objectURL: string;
    filename: string;
}

const getRequestInfo: (url: string) => RequestInfo
= (url) => {
    const parts = url.split('/');
    const query = parts.find(_ => _.startsWith('?')) || '';
    const path =  parts.filter(_ => _ && !/^(\?|http|api)/i.test(_)).join('/');

    return `${appConfig.apiGateway}api/v1/${path}${query}`;
};

const getRequestInit: (method: RequestMethod, headers: Headers, data?: any) => RequestInit
= (method, headers, data) => {
    const noBodyMethods = [
        RequestMethod.GET,
        RequestMethod.HEAD,
        RequestMethod.OPTIONS,
        RequestMethod.DELETE
    ];
    const fetchObj = {
        method: method,
        headers: headers
    };
    const fetchWithBody = {
        body: JSON.stringify(data || {}, (key, value)=> (value !== null && value !== '') ? value : undefined),
        ...fetchObj
    };

    return noBodyMethods.some(m => m === method) ? fetchObj : fetchWithBody;
};

const getHeaders: (headers?: HeadersInit, accessToken?: string) => Headers
= (headers, accessToken) => {
    const authorizationKey = 'X-AUTHORIZATION';
    const defaultHeaders = [['Accept', 'application/json'], ['Content-Type', 'application/json']];

    let updated = (headers as Array<string[]>) || defaultHeaders;
    const authHeader = updated.find(_ => _[0] === authorizationKey);

    if (!authHeader && accessToken) {
        updated.push([authorizationKey, accessToken]);
    } else if(authHeader && accessToken)  {
        authHeader[1] = accessToken;
    } else {
        updated = updated.filter(_ => _[0] !== authorizationKey);
    }

    return new Headers(updated);
};

const refreshAuth: (refreshToken: string) => Promise<AuthTokens>
= async (token) => {
    const url =`users/tokens`;
    const response = await authorize({url, method: RequestMethod.PUT}, token);
    const result = await getResultFrom<RefreshTokenResult>(response);

    return {
        accessToken: result.access_token,
        refreshToken: result.refresh_token
    };
};

const safeRequest: (request: RequestInfo, data: RequestInit, refreshToken?:string) => Promise<Response>
= async (request, data, refreshToken) => {
    let response = await window.fetch(request, data);
        
    if (response.status === 401 && refreshToken) {
        const refreshedResult = await refreshAuth(refreshToken);
        tokenRefreshed(refreshedResult);

        const updatedData = {
            ...data,
            headers: getHeaders(data.headers, refreshedResult.accessToken)
        };

        response = await window.fetch(request, updatedData);
    }

    return response;
};

export const callAPI: (apiRequest: ApiRequest) => Promise<Response>
= async (apiRequest) => {
    try {
        const { url, method, data, auth } = apiRequest;
        const headers = getHeaders(apiRequest.headers, auth?.accessToken);
        const request = getRequestInfo(url);
        const fetchData =  getRequestInit(method, headers, data);

        return await safeRequest(request, fetchData, auth?.refreshToken);
    } catch (reason) {
        return Promise.reject({error: reason.message, info: { url: apiRequest.url, stack: reason.stack } });
    }
}

export const authorize: (apiRequest: ApiRequest, accessToken?: string) => Promise<Response>
= async (apiRequest, accessToken) => {
    const { url, method, data } = apiRequest;
    const headers = getHeaders(apiRequest.headers, accessToken);
    const request = getRequestInfo(url);

    try {
        const fetchData =  getRequestInit(method, headers, data);
        return  await window.fetch(request, fetchData);
    } catch(reason) {
        return Promise.reject({error: reason.message, info: { url: request, stack: reason.stack } });
    }
};

export const download: (url: string, auth: AuthTokens) => Promise<DownloadResult>
= async (url, auth) => {
    try {
        const response = await callAPI({ url, method: RequestMethod.GET, auth });

        if(response.ok) {
            const blob = await response.blob();
            const dispositionValue = String(response.headers.get('Content-Disposition'));
            const findNameMatch = /filename="(.+)"/ig.exec(dispositionValue) || [];

            return {
                objectURL: window.URL.createObjectURL(blob),
                filename: findNameMatch.pop() || `${new Date().toJSON().split('T')[0]}.csv`
            };
        }

        throw new Error(await response.json());
    }
    catch(reason) {
        return Promise.reject({error: reason.message, info: { url: url, stack: reason.stack } });
    }
};

export const getAuthTokens: (user: UserAuthority) => AuthTokens = (user) => ({ accessToken: user.accessToken, refreshToken: user.refreshToken });