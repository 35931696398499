const config = {
    mapBox: {
        token: 'sk.eyJ1Ijoidnd2b2xvZHlhIiwiYSI6ImNrNmZkMGQ1eTBhbDEzbWxtY2kxOTZrdGoifQ.NSOqxITZFYRyYSYr_H-liQ',
        attribution: [
            {text: '© Mapbox', link:'https://www.mapbox.com/about/maps/'},
            {text: '© OpenStreetMap', link:'http://www.openstreetmap.org/about/'},
            {text: 'Improve this map', link:'https://www.mapbox.com/map-feedback/#/-74.5/40/10'}
        ],
        tiles: {
            sm: 'https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/256/{z}/{x}/{y}',
            lg: 'https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}'
        },
        getTilesURL: () => `${config.mapBox.tiles.sm}?access_token=${config.mapBox.token}`,
        getAttributionString: () => config.mapBox.attribution.map(_ => `<a href='${_.link}'>${_.text}</a>`).join(' | ')
    },
    apiGateway: 'https://inspectree.net/',
    contacts: {
        phone: '+38063 448 58 92',
        email: 'inspectree.net@gmail.com',
        facebook: 'https://www.facebook.com/inspectree'
    },
    enableCache: false,
    GA: 'UA-154550161-1',
    isDevelopment: false
};

export default { ...config };
