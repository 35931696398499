import React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps }from "react-router-dom";
import { Jumbotron, Button } from 'react-bootstrap';
import { ApplicationState, UserAuthority } from '../../redux/app-state';
import { authoritySelector, } from '../../redux/selectors';
import { toast, ToastOptions } from 'react-toastify';
import { NEW_VERSION_AVAILABLE } from '../../types/constants';
import Config from '../../app-config';
import * as Bowser from "bowser";

interface Props extends RouteComponentProps { user: UserAuthority; }
declare type State = { error?: Error, info?: Object }

class UnhandledErrorsBoundry extends React.PureComponent<Props,State> {
    constructor(props:any) {
        super(props);
        this.state = {};
        this.notifyAboutNewVersion = this.notifyAboutNewVersion.bind(this);
    }

    componentDidMount() {
        window.addEventListener(NEW_VERSION_AVAILABLE, this.notifyAboutNewVersion);
    }

    componentWillUnmount() {
        window.removeEventListener(NEW_VERSION_AVAILABLE, this.notifyAboutNewVersion);
    }

    componentDidCatch(error: Error, info: Object) {
        if (/Unauthorized/ig.test(error.message)) {
            const { history } = this.props;
            history.push('/');
            return;
        }
        this.setState({ error, info });
    }

    getMailToLink() {
        const { user } = this.props;
        const { error, info } = this.state;
        const userAgentInfo = JSON.stringify(Bowser.parse(window.navigator.userAgent));
        const recipient = encodeURI(Config.contacts.email);
        const emailSubject = encodeURIComponent(`Додаток 'Inspectree' несподівано завершив роботу.`);
        const emailBody = encodeURIComponent(`
        <table style='width:100%'>
            <tr style='background: silver'>
                <td colspan='2'>Відомості про Користувача<td>
            </tr>
            <tr>
                <td colspan='2'>${userAgentInfo}<td>
            </tr>
            <tr>
                <td><i>${user.name || 'Анонім'}</i></td>
                <td>(${user.uid || ''})</td>
            </tr>
            <tr style='background: silver'>
                <td colspan='2'>Відомості про Помилку<td>
            </tr>
            <tr>
                <td>${error?.name || ''}</td>
                <td><i>${error?.message || ''}</i></td>
            </tr>
            <tr>
                <td colspan='2'>${error?.stack || ''}<td>
            </tr>
            <tr style='background: silver'>
                <td colspan='2'>${info?JSON.stringify(info):''}<td>
            </tr>
        </table>`);

        return `mailto:${recipient}?subject=${emailSubject}&body=${emailBody}`;
    }

    forceReloadPage() {
        window.location.replace('/');
    }

    notifyAboutNewVersion() {
        const options = {
            autoClose: false,
            closeButton: false,
            closeOnClick: false,
            draggable: false,
            className: 'new-version-notification',
            bodyClassName: 'new-version-notification-body',
            position: toast.POSITION.BOTTOM_CENTER,
        } as ToastOptions;

        toast(<div className="p-3 text-dark">
                <h5>
                    <i className="fas fa-cloud-download-alt mr-2"></i>
                    <b>Доступна нова версія додатку.</b>
                </h5>
                <h6>
                    Закрийте інші вкладки додатку і натисніть - 'Оновоити'
                </h6>
                <div>
                    <Button variant="dark" onClick={this.forceReloadPage}>Оновити</Button>
                </div>
            </div>, options);
    }

    render() {
        const { error } = this.state;
        return  error ? (
            <Jumbotron className="m-auto vh-100 d-flex justify-content-center align-items-center">
                <div className="m-5 d-flex alert alert-danger" style={{alignItems: 'center', alignContent: 'center'}}>
                    <i className="fas fa-4x fa-bomb m-2" ></i>
                    <div className="m-4">
                        <h3>Сталася несподівана помилка</h3>
                        <h5>через що додаток призупинив виконання</h5>
                        <hr />
                        <a className="btn btn-danger mr-2" href={this.getMailToLink()}>Повідомити про проблему</a>
                        <Button variant="danger" onClick={this.forceReloadPage}>
                            Перезапустити
                        </Button>
                    </div>
                </div>
            </Jumbotron>
        ) : this.props.children;
    }
};

const map: (state: ApplicationState, ownPros: any) => Props = (state, ownProps) => {
    const user = authoritySelector(state);
    return { user, ...ownProps };
}

export default connect(map)(withRouter(UnhandledErrorsBoundry));